export const ENTITY_TABLE_NAME_PATTERN = {
    CSGRSV: '^RV[0-9]{7}', // e.g: RV2108810

    CSGCABMOV: '^[A-Z]{3}[U][0-9]{7}', // e.g: e.g: ZSCU8519137

    CSGPDA: '^PG[0-9]{6}', // e.g: PG210023

    CSGPDAL: '^PDA[0-9]{6}', // e.g: PDA210013

    CSGOFEVEN: [
        '^O[0-9]{6,7}', // e.g: O2000008
        '^QT[0-9]{7}', // e.g: QT2100009
    ],

    CSGCTZ: [
        '^COT[0-9]{6}', // e.g: COT000002
        '^QT[0-9]{7}-[A-Z0-9]{3,4}', // e.g: QT2100229-7STA
    ],

    CSGALQINV: [
        '^CBP[0-9]{7}', // e.g: CBP2000008
        '^CP[0-9]{7}', // e.g: CP2100067
    ],

    CSGBKG: [
        '^[0-9]{8,12}', //e.g: 222222222
        '^[a-zA-Z]{3,6}[0-9]{4,8}[A-Z]', // e.g: BCNTUN32936X
        '^[a-zA-Z]{3,6}[0-9]{4,8}', // e.g: BCNTUN32936
        '^(SUDU|sudu)+[a-zA-Z0-9]{0,12}', // e.g: SUDU240879345697
        '^[a-zA-Z]{6,}[0-9]{5,}[x,X,r,R]{1}', // e.g: BCNTUN32936X, BCNTUN32936r
        '^[a-zA-Z]{6,}COPIA[ ][0-9]{2,}', // e.g: BCNTUNCOPIA20
        '^[A-Za-z]{2}[0-9]{6}', // e.g: ME125489
        '^[D][a-zA-Z]{3,6}[0-9]{4,8}', //e.g: DVLCBND0001 DENIZ
    ],

    CSGBOL: [
        '^[0-9]{8,12}',
        '^[a-zA-Z]{3,6}[0-9]{4,8}[A-Z]', // e.g: BCNTUN32936X
        '^[a-zA-Z]{3,6}[0-9]{4,8}', // e.g: BCNTUN32936
        '^(SUDU|sudu)+[a-zA-Z0-9]{0,12}', // e.g: SUDU240879345697
        '^[a-zA-Z]{6,}[0-9]{5,}[x,X,r,R]{1}', // e.g: BCNTUN32936X, BCNTUN32936r
        '^[a-zA-Z]{6,}COPIA[ ][0-9]{2,}', // e.g: BCNTUNCOPIA20
        '^[D][a-zA-Z]{3,6}[0-9]{4,8}', //e.g: DVLCBND0001 DENIZ
    ],

    // prettier-ignore
    CSGBUQVIA: [
        '^[a-zA-Z]+[*][0-9]{2}[\/][0-9]{2}', // e.g: ELYS*02/22
        '^[0-9]{2}[\/][0-9]{2}', // e.g: 02/22
    ],
};

export const VFORWARDING_CODE_PATTERN = {
    CODE: '^LOG[T | L][0-9]{6}', // e.g: e.g: LOGT101808, LOGL101808
};
