import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userNameObservablePrivate$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  userNameObservableData(newUserName: string) {
    this.userNameObservablePrivate$.next(newUserName);
  }

  get userNameObservable() {
      return this.userNameObservablePrivate$.asObservable();
  }
}
