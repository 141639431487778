import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpBaseInterceptor } from './interceptors/http-base.interceptor.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpBaseInterceptor,
            multi: true,
        },
    ],
})
export class AuthModule {}
