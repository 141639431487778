export const STATE_CONSTANTS = {
    SAVED_MESSAGE: 'Saved state',
    SAVED_ERROR_MESSAGE: (error: string) => {
        let message: string = 'State save failed.';

        if (error?.length) {
            message = `${message} Error: ${error} `;
        }

        return message;
    },
};
