export const TABLE_NAME_GENERAL_TYPE = {
    ENTITY_MAIL: 'CSGENTMAIL',

    BOOKING: 'CSGBKG',

    BL: 'CSGBOL',

    VESSEL_VOYAGE: 'CSGBUQVIA',

    EQUIPMENT_CONTROL: 'CSGCABMOV',

    TRAMPING_QUOTATION: 'CSGCTZ',

    SHIPPING_QUOTATION: 'CSGOFEVEN',

    PDA: 'CSGPDA',

    TRAMPING_RESERVATION: 'CSGRSV',

    TRAMPING_RENTAL: 'CSGALQINV',
};
