import { Injectable, Injector } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { TokenService } from '../auth/services/token.service';

import { LOGIN } from '../../constants/login.constants';
import { OutlookService } from 'src/app/core/services/outlook.service';
import { LOCAL_STORAGE } from 'src/app/constants/localStorage.constant';
import { UserService } from './user/user.service';
import { UserService as HttpUserService } from '../http-services/user/user.service';
import { PermissionService } from './permission/permission.service';
import { Router } from '@angular/router';
import { ROUTES_DEFINITION } from 'src/app/constants/router-definitions.constant';
import { NotifyService } from './notify.service';
import { SessionStorageService } from './session-storage/session-storage.service';
import { MicrosoftGraphService } from '../microsoft-graph/microsoft-graph.service';

@Injectable()
export class InitializeService {
    constructor(
        private _localStorageService: LocalStorageService,
        private _tokenService: TokenService,
        private _injector: Injector,
        private _userService: UserService,
        private _httpUserService: HttpUserService,
        private _permissionService: PermissionService,
        private _router: Router,
        private _sessionStorageService: SessionStorageService
    ) {}

    /**
     * @description Launch previous init WebApp and login with generic credentials
     * @returns Promise<boolean>
     */
    initializeApp(): Promise<boolean> {
        const _outlookService: OutlookService = this._injector.get(OutlookService);
        const _notifyService: NotifyService = this._injector.get(NotifyService);

        _outlookService.getOutlookToken();

        const userName: string = _outlookService.getUserToFrom();
        const password: string = LOGIN.GENERIC_PASSWORD;
        return new Promise((resolve: any) => {
            const USER_NAME_OFFICE_CONTEXT: string = _outlookService.getState(LOCAL_STORAGE.USER_NAME);
            const TOKEN_OFFICE_CONTEXT: string = _outlookService.getState(LOCAL_STORAGE.TOKEN);

            if (USER_NAME_OFFICE_CONTEXT && TOKEN_OFFICE_CONTEXT) {
                this._localStorageService.setUserName(USER_NAME_OFFICE_CONTEXT.trim());
                this._localStorageService.setToken(TOKEN_OFFICE_CONTEXT.trim());

                this._userService.userNameObservableData(USER_NAME_OFFICE_CONTEXT.trim());
                this._httpUserService.getPermissions().subscribe((permissionList: string[]) => {
                    this._permissionService.setPermissionList(permissionList);
                });

                this._tokenService.updateToken(TOKEN_OFFICE_CONTEXT).then(
                    () => {
                        resolve();
                    },
                    (error) => {
                        _notifyService.info(`Auto login failed with user ${USER_NAME_OFFICE_CONTEXT}`);
                        this.onAuthenticateFail(error, _outlookService);
                        resolve();
                    }
                );

                resolve();
                return;
            }

            if (userName && password) {
                resolve();
                this._tokenService
                    .getToken(userName, password)
                    .toPromise()
                    .then(
                        () => {
                            resolve();
                        },
                        (error) => {
                            _notifyService.info(`Auto login failed with user ${userName}`);
                            this.onAuthenticateFail(error, _outlookService);
                            resolve();
                        }
                    );
            } else {
                resolve();
                return;
            }
        });
    }

    /**
     * @description Handle Authentication error and push notify
     * @param  {any} error
     */
    private onAuthenticateFail(error: any, outlookService: OutlookService): void {
        console.error(error);
        outlookService.removeState(LOCAL_STORAGE.USER_NAME);
        outlookService.removeState(LOCAL_STORAGE.TOKEN);
        outlookService.saveState();
        this._localStorageService.clear();
        this._sessionStorageService.logError('Auto login failed with generic credentials', error);
        this._permissionService.deletePermissionList();
        this._router.navigate([ROUTES_DEFINITION.LOGIN]);
    }
}
