export const ROUTES_DEFINITION = {
    // GLOBAL
    BASE: '',

    LOGIN: '/login',

    // ATTACHMENT
    ADD_ATTACHMENT: '/attachment/add',
    EXPORT_ATTACHMENT: '/attachment/export',

    // MICROSOFT
    MICROSOFT_LOGIN_SUCCESS: '/microsoft/login-success',
};
