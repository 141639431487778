import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { auditTime, filter, take, takeUntil } from 'rxjs/operators';
import { MicrosoftGraphService } from './core/microsoft-graph/microsoft-graph.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { OutlookService } from './core/services/outlook.service';
import { ROUTES_DEFINITION } from './constants/router-definitions.constant';
import { MICROSOFT_GRAPH_CONSTANTS } from './constants/microsoft-graph.constant';
import { LocalStorageService } from './core/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    isIframe = false;
    isLogged = false;
    hostName = '';

    private readonly _destroying$ = new Subject<void>();

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private _msalAuthService: MsalService,
        private _msalBroadcastService: MsalBroadcastService,
        private _microsoftGraphService: MicrosoftGraphService,
        private _router: Router,
        private _outlookService: OutlookService,
        private _localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.hostName = this._outlookService.getHostName();
        this._outlookService.getSharedProperties();

        this.listenRedirectToToFirstRoute();
        this.listenMicrosoftBroadcastService();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    /**
     * @private
     * @description Manage login actions
     * @return void
     */
    private manageMicrosoftLogin(): void {
        this.isLogged = this._msalAuthService.instance.getAllAccounts().length > 0;

        if (this.isLogged) {
            this._msalAuthService.instance.setActiveAccount(this._msalAuthService.instance.getAllAccounts()[0]);
            console.info(MICROSOFT_GRAPH_CONSTANTS.LOGGED_IN_MESSAGE);
            this._microsoftGraphService.refreshToken().subscribe(() => {
                this.manageRedirect();
            });
        } else {
            this.login();
        }
    }

    /**
     * @description Login with microsoft
     * @returns void
     */
    login(): void {
        if (this._outlookService.isAvailableLoginWithPopUP()) {
            this.loginPopUp();
            return;
        }

        this.loginRedirect();
    }

    /**
     * @description Logout with microsoft
     * @returns void
     */
    logout(): void {
        this._msalAuthService.logoutRedirect();
    }

    /**
     * @private
     * @description Listen microsoft broadcast service
     * @returns void
     */
    private listenMicrosoftBroadcastService(): void {
        this._msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                auditTime(1000),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.manageMicrosoftLogin();
            });
    }

    /**
     * @private
     * @description Listen redirect to first route
     * @returns void
     */
    private listenRedirectToToFirstRoute(): void {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
                take(1)
            )
            .subscribe((event) => {
                this.postRedirectToFirstRoute(event);
            });
    }

    /**
     *
     * @private
     * @description Post redirect to first route
     * @param {unknown} event
     * @returns void
     */
    private postRedirectToFirstRoute(event: unknown): void {
        const IS_MICROSOFT_LOGIN_SUCCESS_ROUTE: boolean =
            event instanceof NavigationEnd &&
            event.urlAfterRedirects.toLowerCase().trim() === ROUTES_DEFINITION.MICROSOFT_LOGIN_SUCCESS.toLowerCase().trim();

        if (IS_MICROSOFT_LOGIN_SUCCESS_ROUTE) {
            this.login();
            return;
        }

        this.redirectAccordingOutlookMode();
    }

    /**
     * @private
     * @description Redirect to login according authRequest
     * @returns Observable<void>
     */
    private loginPopUp(): Observable<AuthenticationResult> {
        if (this.msalGuardConfig.authRequest) {
            return this._msalAuthService.loginPopup({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        }

        return this._msalAuthService.loginPopup();
    }

    /**
     * @private
     * @description Redirect to login according authRequest
     * @returns Observable<void>
     */
    private loginRedirect(): Observable<void> {
        if (this.msalGuardConfig.authRequest) {
            return this._msalAuthService.loginRedirect({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        }

        return this._msalAuthService.loginRedirect();
    }

    /**
     * @private
     * @description Redirect according to outlook mode
     * @returns void
     */
    private redirectAccordingOutlookMode(): void {
        setTimeout(() => {
            if (this._outlookService.isOutlookOpenWriteMode()) {
                this._router.navigate([ROUTES_DEFINITION.ADD_ATTACHMENT]);
                return;
            }
            this._router.navigate([ROUTES_DEFINITION.EXPORT_ATTACHMENT]);
        }, 100);
    }

    /**
     * @private
     * @description Manage user session
     * @returns void
     */
    private manageRedirect(): void {
        const USER_NAME: string = this._localStorageService.getUserName() ?? '';
        if (!USER_NAME.length) {
            setTimeout(() => {
                this._router.navigate([ROUTES_DEFINITION.LOGIN]);
            }, 100);
        } else {
            this.redirectAccordingOutlookMode();
        }
    }
}
