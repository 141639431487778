import { TABLE_NAME_GENERAL_TYPE } from 'src/app/constants/table-name-general-types.constants';
import { EntityType } from '../enum/entity-type.enum';

export class SearchTableIdFilter {
    constructor(public code: string, public entityType: EntityType, public userName: string) {}
}

export class SearchTableIdResult {
    constructor(public tableId: number, public divisionId: number, public divisionDescription: string, public companyDivisionId: number) {}
}

export class SearchTableIdResultHelper {
    static mapToObject(result): SearchTableIdResult {
        return new SearchTableIdResult(result.TableId, result.DivisionId, result.InfoDivisionDescription, result.CompanyDivisionId);
    }

    static mapToObjectList(result: any): SearchTableIdResult[] {
        const items = [];
        const itemsToTransform = result.Data || result;
        itemsToTransform.forEach((item: any) => {
            items.push(this.mapToObject(item));
        });

        return items;
    }
}

export class MatchBookingAndBLHelper {
    static mapToObject(result): string {
        const { IsBL, IsBooking } = result;

        if (IsBL && IsBooking) {
            return TABLE_NAME_GENERAL_TYPE.BOOKING;
        }

        if (IsBooking) {
            return TABLE_NAME_GENERAL_TYPE.BOOKING;
        }

        if (IsBL) {
            return TABLE_NAME_GENERAL_TYPE.BL;
        }
    }
}
