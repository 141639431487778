import { Injectable } from '@angular/core';
import { LOCAL_STORAGE } from 'src/app/constants/localStorage.constant';
import { ILog } from 'src/app/shared/models/log.model';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {
    constructor() {}

    logError(message: string, errorObject: unknown): void {
        const LOG_ENTRY: ILog = {
            timestamp: new Date().toISOString(),
            message: message,
            errorObject: errorObject,
        };
        const ERROR_LIST = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE.APP_ERRORS) || '[]');
        ERROR_LIST.push(LOG_ENTRY);

        sessionStorage.setItem(LOCAL_STORAGE.APP_ERRORS, JSON.stringify(ERROR_LIST));
    }

    getErrors(): any[] {
        return JSON.parse(sessionStorage.getItem(LOCAL_STORAGE.APP_ERRORS) || '[]');
    }
}
