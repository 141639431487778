import { DocDownload, DocDownloadHelper } from 'src/app/shared/models/docs/docDownload.model';
import { IMicrosoftGraphAttach } from './microsoft-graph/microsoft-graph-attach.model';

export class OutlookEmailInfo {
    constructor(
        public emailId: string,
        public subject: string,
        public isForwarding: boolean,
        public from: OutlookUserEmailInfo,
        public to: OutlookUserEmailInfo[],
        public sender: OutlookUserEmailInfo,
        public carbonCopy: OutlookUserEmailInfo[],
        public userName: string,
        public attachmentFiles: DocDownload[],
        public isFromSharedMailbox: boolean
    ) {}
}

export class OutlookEmailInfoHelper {
    static mapToObject(result: any): OutlookEmailInfo {
        if (result.item.itemId === undefined) {
            return new OutlookEmailInfo(null, null, null, null, null, null, null, result.initialData.userEmailAddress.split('@')[0], null, false);
        } else {
            return new OutlookEmailInfo(
                result.item.itemId,
                result.item.normalizedSubject,
                result.item.normalizedSubject.toString().indexOf('RV:') > -1,
                OutlookUserEmailInfoHelper.mapToObject(result.item.from),
                OutlookUserEmailInfoHelper.mapToObjectList(result.item.to),
                OutlookUserEmailInfoHelper.mapToObject(result.item.sender),
                OutlookUserEmailInfoHelper.mapToObjectList(result.item.cc),
                result.initialData.userEmailAddress.split('@')[0],
                null,
                result.initialData?.isFromSharedFolder ?? false
            );
        }
    }
}

export class OutlookUserEmailInfo {
    constructor(public name: string, public email: string) {}
}

export class OutlookUserEmailInfoHelper {
    static mapToObject(result: any): OutlookUserEmailInfo {
        return new OutlookUserEmailInfo(result.displayName, result.emailAddress);
    }

    static mapToObjectList(result): any[] {
        const items = [];
        const itemsToTransform = result.Data || result;
        itemsToTransform.forEach((item) => {
            items.push(this.mapToObject(item));
        });

        return items;
    }
}
export class OutlookFileInfo extends DocDownload {
    constructor() {
        super();
    }
}

export class OutlookFileInfoHelper {
    static mapToObject(result: IMicrosoftGraphAttach): OutlookFileInfo {
        const defaultDocDownloadObject: DocDownload = DocDownloadHelper.DefaultObject();
        const fileName: string = result.name.toString();
        defaultDocDownloadObject.id = result.id;
        defaultDocDownloadObject.name = fileName.split('.' + fileName.split('.').pop())[0];
        defaultDocDownloadObject.extension = '.' + fileName.split('.').pop();
        defaultDocDownloadObject.byteArray = result.contentBytes;

        return defaultDocDownloadObject;
    }

    static mapToObjectList(result: IMicrosoftGraphAttach[]): any[] {
        const items = [];
        result.forEach((item) => {
            if (!item.isInline) {
                items.push(this.mapToObject(item));
            }
        });

        return items;
    }
}
