import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ROUTES_DEFINITION } from 'src/app/constants/router-definitions.constant';

import { AuthService } from 'src/app/core/auth/services/auth.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { OutlookService } from 'src/app/core/services/outlook.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    errorLoginMessage: string;

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _authService: AuthService,
        private _router: Router,
        private _localStorageService: LocalStorageService,
        private _outlookService: OutlookService
    ) {}

    ngOnInit(): void {
        this.createLoginForm();
    }

    /**
     * @description Create Form for Add Attachment
     */
    createLoginForm(): void {
        this.loginForm = this._formBuilder.group({
            userName: [null, [Validators.required]],
            password: [null, [Validators.required]],
        });
    }

    /**
     * @description Call Service for Login and save token
     * @returns void
     */
    login(): void {
        if (this.loginForm.valid) {
            this.errorLoginMessage = '';
            const { userName, password } = this.loginForm.value;
            this._authService.login(userName, password).subscribe(
                (token: string) => {
                    if (!token?.length) {
                        this.errorLoginMessage = 'Could not get login';
                    } else {
                        this._localStorageService.setToken(token);
                        this.redirectAccordingOutlookMode();
                    }
                },
                (error) => {
                    this.errorLoginMessage = error && error.status && error.status === 401 ? 'Incorrect username or password' : 'Username not found';
                }
            );
        }
    }

    /**
     * @private
     * @description Redirect according to outlook mode
     * @returns void
     */
    private redirectAccordingOutlookMode(): void {
        setTimeout(() => {
            if (this._outlookService.isOutlookOpenWriteMode()) {
                this._router.navigate([ROUTES_DEFINITION.ADD_ATTACHMENT]);
                return;
            }
            this._router.navigate([ROUTES_DEFINITION.EXPORT_ATTACHMENT]);
        }, 100);
    }
}
