<div class="border rounded p-3 mb-3" *ngIf="documentListConfiguration">
    <div class="row mb-2">
        <div class="col-12">
            <h6 class="text-muted">
                Document List
                <span *ngIf="documentListConfiguration.documentListTitle !== null">
                    of <strong> {{ documentListConfiguration.documentListTitle }} </strong>
                </span>
            </h6>
        </div>
    </div>

    <div class="row">
        <div class="col-12" *ngIf="emailAsDocument !== null && emailAsDocument !== undefined">
            <div class="row mb-1">
                <div
                    class="d-flex align-items-center"
                    [ngClass]="{
                        'col-12':
                            !documentListConfiguration?.selectDocumentType ||
                            documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType,
                        'col-6': !(
                            !documentListConfiguration?.selectDocumentType ||
                            documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType
                        )
                    }"
                >
                    <span class="material-icons-two-tone filter-primary mx-2">email</span>
                    <ng-container *ngIf="emailAsDocument && emailAsDocument.name; else loadingEmail">
                        <span class="mx-1">{{ emailAsDocument.name + '.' + outlookConstants.EXTENSION.MSG }} </span>
                    </ng-container>
                    <ng-template #loadingEmail>
                        <app-spinner-loading [message]="'Download email from Outlook server'"></app-spinner-loading>
                    </ng-template>
                </div>

                <div
                    *ngIf="
                        !documentListConfiguration?.selectDocumentType ||
                        documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType
                    "
                    class="mb-2 mt-2 col-8 d-flex justify-content-start align-items-center"
                >
                    <div class="pr-4 autocomplete w-100" [ngClass]="{ 'searching': filteredTypeDocumentOnDocumentId === emailAsDocument.id }">
                        <input
                            id="{{ selectDocumentInputId + emailAsDocument.id }}"
                            type="text"
                            title="Document Type"
                            class="form-control form-control-sm text-muted"
                            [disabled]="uploadingEmail"
                            [placeholder]="
                                emailAsDocument.typeId === null
                                    ? 'Select document type'
                                    : emailAsDocument.type + ' | ' + emailAsDocument.typeDescription
                            "
                            autofocus="autofocus"
                            autocomplete="off"
                            (focus)="
                                filteredTypeDocumentList = [];
                                filteredTypeDocumentOnDocumentId = emailAsDocument.id;
                                getTypeDocuments($event, emailAsDocument)
                            "
                            (blur)="lostFocusTypeDocumentAutocomplete()"
                            (keydown)="getTypeDocuments($event, emailAsDocument)"
                        />

                        <div class="container-list">
                            <ul
                                class="list-group list-group-flush list-autocomplete border rounded mt-1"
                                *ngIf="filteredTypeDocumentOnDocumentId === emailAsDocument.id && filteredTypeDocumentList.length > 0"
                            >
                                <li *ngFor="let typeDocument of filteredTypeDocumentList" class="list-group-item">
                                    <span class="d-block" role="button" (click)="selectTypeDocument(typeDocument, emailAsDocument)">
                                        {{ _autocompleteService.formatFormsValues(typeDocument, ['code', 'description']) }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    class="mb-2 mt-2 d-flex justify-content-end align-items-center"
                    [ngClass]="{
                        'col-4':
                            !documentListConfiguration?.selectDocumentType ||
                            documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType,
                        'col-6': !(
                            !documentListConfiguration?.selectDocumentType ||
                            documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType
                        )
                    }"
                >
                    <ng-container *ngIf="!uploadingEmail; else uploadingEmailProcess">
                        <button
                            class="btn btn-sm btn-primary d-flex align-items-center"
                            [disabled]="!emailAsDocument?.id || uploadingEmail"
                            (click)="uploadEmail(emailAsDocument)"
                        >
                            <span class="material-icons-two-tone filter-white">
                                {{ documentListConfiguration.documentActionIcon }}
                            </span>
                            <span class="d-sm-block d-md-block d-none"> Upload email </span>
                        </button>
                    </ng-container>
                    <ng-template #uploadingEmailProcess>
                        <span class="d-none d-sm-block d-md-block d-lg-block">
                            <app-spinner-loading [message]="'Uploading'"></app-spinner-loading>
                        </span>
                        <span class="d-block d-sm-none d-md-none d-lg-none">
                            <app-spinner-loading [message]="''"></app-spinner-loading>
                        </span>
                    </ng-template>
                </div>
            </div>
        </div>

        <ng-container *ngIf="documentList.length > 0; else emptyDocumentList">
            <div class="col-12">
                <div class="table-responsive">
                    <table class="table table-hover col-sm-12 table-bordered table-striped table-condensed cf">
                        <thead class="text-muted">
                            <tr>
                                <th class="select-column">
                                    <div role="button" aria-pressed="false" [attr.data-selected]="allSelected" (click)="preventSelectAllDocuments()">
                                        <span class="material-icons-two-tone">
                                            {{ allSelected ? 'indeterminate_check_box' : 'check_box_outline_blank' }}
                                        </span>
                                    </div>
                                </th>
                                <th class="name-column">Name</th>
                                <th
                                    class="type-column"
                                    *ngIf="
                                        !documentListConfiguration?.selectDocumentType ||
                                        documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType
                                    "
                                >
                                    Type
                                </th>
                                <th *ngIf="documentListConfiguration.hiddenActions && documentListConfiguration.actionsInLine"></th>
                            </tr>
                        </thead>
                        <tbody class="border-top-0">
                            <tr *ngFor="let document of documentList">
                                <td class="select-column">
                                    <div
                                        class="text-muted"
                                        [attr.data-selected]="document.selected"
                                        role="button"
                                        aria-pressed="false"
                                        (click)="selectDocument(document)"
                                    >
                                        <span class="material-icons-two-tone">
                                            {{ document.selected ? 'check_box' : 'check_box_outline_blank' }}
                                        </span>
                                    </div>
                                </td>
                                <td class="name-column">
                                    <div>
                                        <span>
                                            {{
                                                document.name +
                                                    (document.extension === outlookConstants.EXTENSION.EML
                                                        ? outlookConstants.EXTENSION.MSG
                                                        : document.extension)
                                            }}
                                        </span>

                                        <span>
                                            <span
                                                class="material-icons-two-tone filter-primary"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="                                    {{
                                                    document.name +
                                                        (document.extension === outlookConstants.EXTENSION.EML
                                                            ? outlookConstants.EXTENSION.MSG
                                                            : document.extension)
                                                }}"
                                            >
                                                info
                                            </span>
                                        </span>
                                    </div>
                                </td>
                                <td
                                    class="type-column"
                                    *ngIf="
                                        !documentListConfiguration?.selectDocumentType ||
                                        documentListConfiguration.selectDocumentType === selectDocumentType.autocompleteByCompanyDivisionAndEntityType
                                    "
                                    [ngClass]="{ 'search-column': filteredTypeDocumentOnDocumentId === document.id }"
                                >
                                    <div class="d-flex align-items-center">
                                        <ng-container
                                            *ngIf="!documentListConfiguration.hiddenChangesDocumentType; else showDocumentTypeWithouChanged"
                                        >
                                            <div class="autocomplete" [ngClass]="{ 'searching': filteredTypeDocumentOnDocumentId === document.id }">
                                                <input
                                                    id="{{ selectDocumentInputId + document.id }}"
                                                    type="text"
                                                    title="Document Type"
                                                    class="form-control form-control-sm text-muted"
                                                    [placeholder]="
                                                        document.typeId === null
                                                            ? 'Select document type'
                                                            : document.type + ' | ' + document.typeDescription
                                                    "
                                                    autofocus="autofocus"
                                                    autocomplete="off"
                                                    (focus)="
                                                        filteredTypeDocumentList = [];
                                                        filteredTypeDocumentOnDocumentId = document.id;
                                                        getTypeDocuments($event, document)
                                                    "
                                                    (blur)="lostFocusTypeDocumentAutocomplete()"
                                                    (keydown)="getTypeDocuments($event, document)"
                                                />

                                                <div class="container-list">
                                                    <ul
                                                        class="list-group list-group-flush list-autocomplete border rounded mt-1"
                                                        *ngIf="
                                                            filteredTypeDocumentOnDocumentId === document.id && filteredTypeDocumentList.length > 0
                                                        "
                                                    >
                                                        <li *ngFor="let typeDocument of filteredTypeDocumentList" class="list-group-item">
                                                            <span class="d-block" role="button" (click)="selectTypeDocument(typeDocument, document)">
                                                                {{ _autocompleteService.formatFormsValues(typeDocument, ['code', 'description']) }}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #showDocumentTypeWithouChanged>
                                            {{ document.type + ' | ' + document.typeDescription }}
                                        </ng-template>

                                        <span
                                            *ngIf="document.isUnique"
                                            class="material-icons-two-tone filter-primary"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Type document is Unique"
                                            [ngClass]="{
                                                'filter-primary': document.isUnique
                                            }"
                                        >
                                            new_releases
                                        </span>
                                    </div>
                                </td>
                                <td *ngIf="documentListConfiguration.hiddenActions && documentListConfiguration.actionsInLine">
                                    <button
                                        class="btn btn-sm btn-light border text-muted d-flex justify-content-center align-items-center"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Tooltip on top"
                                        (click)="emitGetDocument(document)"
                                    >
                                        <span class="material-icons-two-tone">
                                            {{ documentListConfiguration.documentActionIcon }}
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="col-12 mt-2">
                <ng-container *ngIf="!uploadingFiles; else uploadingFilesProcess">
                    <button
                        type="submit"
                        class="btn btn-sm btn-primary w-100"
                        tabindex="5"
                        [disabled]="selectedDocuments.length === 0"
                        (click)="executeActionDocumentList()"
                    >
                        <span class="icon material-icons-two-tone {{ documentListConfiguration.documentActionClass }} ">
                            {{ documentListConfiguration.documentActionIcon }}
                        </span>
                        <span>
                            {{ documentListConfiguration.documentsActionTitle }}
                        </span>
                        <span
                            class="badge text-muted"
                            [ngClass]="{
                                'bg-white': selectedDocuments.length > 0,
                                'bg-primary': selectedDocuments.length > 0
                            }"
                        >
                            {{ selectedDocuments.length > 0 ? selectedDocuments.length : ' ' }}
                        </span>
                    </button>
                </ng-container>
                <ng-template #uploadingFilesProcess>
                    <app-spinner-loading [message]="'Processing files'"></app-spinner-loading>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #emptyDocumentList>
            <div class="col-12">
                <div class="border rounded text-muted text-center py-2 mx-4">The e-mail does not contain any attachments.</div>
            </div>
        </ng-template>
    </div>
</div>
