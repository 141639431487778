import { environment } from 'src/environments/environment';

export const MICROSOFT_GRAPH_CONSTANTS = {
    GET_TO_EMAIL_LIST_ENDPOINT: (emailList: string) => {
        return `${environment.apiConfig.usersGraphUrl}?$filter=mail in ('${emailList}')`;
    },

    GET_ATTACH_FILES_LIST_FROM_ME_ENDPOINT: (emailId: string) => {
        return `${environment.apiConfig.meGraphUrl}/messages/${emailId}/attachments`;
    },

    GET_ATTACH_FILES_LIST_FROM_USERS_ENDPOINT: (userId: string, emailId: string) => {
        return `${environment.apiConfig.usersGraphUrl}/${userId}/messages/${emailId}/attachments`;
    },

    GET_EMAIL_FROM_ME_ENDPOINT: (emailId: string) => {
        return `${environment.apiConfig.meGraphUrl}/messages/${emailId}/$value`;
    },

    GET_EMAIL_FROM_USERS_ENDPOINT: (userId: string, emailId: string) => {
        return `${environment.apiConfig.usersGraphUrl}/${userId}/messages/${emailId}/$value`;
    },

    COULD_NOT_GET_EMAIL_FROM_MICROSOFT_CLOUD: 'Could not get email from Microsoft Cloud',
    COULD_NOT_GET_ATTACHED_FILES_FROM_MICROSOFT_CLOUD: 'Could not get attached files from Microsoft Cloud',
    COULD_NOT_GET_BASE_64: 'Could not get Base64 from Microsoft Cloud',

    LOGGED_IN_MESSAGE: 'Logged with Microsoft',

    ATTEMPT_CALL_ENDPOINT: (requestAttempt: number, endpoint: string) => {
        return `Attempt ${requestAttempt} | ${endpoint}`;
    },

    RETRY_CALL_ENDPOINT: (endpoint: string) => {
        return `Retrying... ${endpoint}`;
    },

    TOKEN_REFRESH_SUCCESSFULLY: 'Token refreshed successfully',
    TOKEN_REFRESH_FAILED_NEED_LOGIN: 'Interaction required. Redirecting to login.',
    TOKEN_REFRESH_FAILED: 'Error acquiring token silently',
};
