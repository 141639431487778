import { Injectable } from '@angular/core';
import { OUTLOOK } from '../../constants/outlook.constant';

import { DocDownload } from 'src/app/shared/models/docs/docDownload.model';
import { OutlookUserEmailInfo, OutlookEmailInfo, OutlookEmailInfoHelper } from 'src/app/shared/models/outlook.model';

import { OutlookMode } from 'src/app/shared/enum/outlook.enum';
import { LOGIN } from 'src/app/constants/login.constants';
import { NotifyService } from 'src/app/core/services/notify.service';
import { MESSAGE } from 'src/app/constants/message.constant';
import { ERROR_MESSAGE } from 'src/app/constants/error-message.constant';
import { STATE_CONSTANTS } from 'src/app/constants/state.constant';
import { LocalStorageService } from './local-storage.service';
import { ISharedProperties } from 'src/app/shared/models/shared-properties.model';

declare const Office: any;

@Injectable({
    providedIn: 'root',
})
export class OutlookService {
    outlookToken: string = null;

    constructor(private _notifyService: NotifyService, private _localStorageService: LocalStorageService) {}

    /**
     * @description Get all info for Outlook item
     * @returns {OutlookEmailInfo}
     */
    getOutlookInfo(): OutlookEmailInfo {
        return OutlookEmailInfoHelper.mapToObject(Office.context.mailbox);
    }

    /**
     * @description Generate a new Outlook token
     * @returns {OutlookEmailInfo}
     */
    getOutlookToken(): void {
        Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, (result) => {
            this.outlookToken = result.value;
        });
    }

    /**
     * @description Get Outlook mode - eg: write, read
     * @returns {OutlookMode}
     */
    getOutlookMode(): OutlookMode {
        return Office.context.mailbox.item.itemId !== undefined ? OutlookMode.read : OutlookMode.write;
    }

    /**
     * @description Get Outlook is read mode
     * @returns {OutlookMode}
     */
    isOutlookOpenReadMode(): boolean {
        return this.getOutlookMode() === OutlookMode.read;
    }

    /**
     * @description Get Outlook is write mode
     * @returns {OutlookMode}
     */
    isOutlookOpenWriteMode(): boolean {
        return this.getOutlookMode() === OutlookMode.write;
    }

    /**
     * @description Get subject from mail using Office API
     * @param {string} defaultName
     * @returns {string}
     */
    getSubject(defaultName?: string): string {
        const SUBJECT: string = this.getOutlookInfo().subject;

        if (!SUBJECT?.length && defaultName) {
            return defaultName;
        }

        return SUBJECT;
    }

    /**
     * @description Detect email is Forwanding
     * @returns {boolean}
     */
    emailIsForwarding(): boolean {
        return this.getOutlookInfo().isForwarding;
    }

    /**
     * @description Get From User and Email Info
     * @returns {OutlookUserEmailInfo[]}
     */
    getFrom(): OutlookUserEmailInfo {
        return this.getOutlookInfo().from;
    }

    /**
     * @description Get From User and Email Info
     * @returns {OutlookUserEmailInfo[]}
     */
    getTo(): OutlookUserEmailInfo[] {
        return this.getOutlookInfo().to;
    }

    /**
     * @description Get Sender User and Email Info
     * @returns {OutlookUserEmailInfo}
     */
    getSender(): OutlookUserEmailInfo {
        return this.getOutlookInfo().sender;
    }

    /**
     * @description Get CC User and Email Info
     * @returns {OutlookUserEmailInfo[]}
     */
    getCarbonCopy(): OutlookUserEmailInfo[] {
        return this.getOutlookInfo().carbonCopy;
    }

    /**
     * @description Get Username from getTo() User and Email Info
     * @returns  {string}
     */
    getUserToFrom(): string {
        const userName: string = this.getOutlookInfo().userName;
        return userName ?? LOGIN.USERNAME;
    }

    /**
     * @description Get Attachment Files, exclude images on body
     * @returns {DocDownload[]} Minimal DocDownload with name, extension, outlookId and id = '0'
     */
    getAttachmentFiles(): DocDownload[] {
        return this.getOutlookInfo().attachmentFiles;
    }

    /**
     * @description Attach de file on Outlook mail using Office API
     * @param  {string} base64
     * @param  {string} fileName
     * @returns void
     */
    attachFileFromBase64(base64: string, fileName: string): void {
        Office.context.mailbox.item.addFileAttachmentFromBase64Async(base64, fileName, { asyncContext: null }, function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                this._sessionStorageService.logError(asyncResult.error.message, asyncResult.error);
                this._notifyService.error(asyncResult.error.message);
            } else {
                this._notifyService.success(MESSAGE.SUCCESFULLY_ATTACHMENT_FILENAME(fileName));
            }
        });
    }

    /**
     * @description Get ItemId from email
     * @returns {string}
     */
    getItemRestId(): string {
        const ITEM_REST_ID: string = this.isFromIOSOutlook() ? this.getItemRestIdFromIOSPlatform() : this.getItemRestIdFromNonIOSPlatform();
        if (!ITEM_REST_ID?.length) {
            this._notifyService.error(ERROR_MESSAGE.COULD_NOT_GET_EMAIL_ID);
        }

        return ITEM_REST_ID;
    }

    /**
     * @description Convert email from Blob to Base64
     * @param  {any} blob
     * @returns {Promise<string | ArrayBuffer>}
     */
    async convertEmailToBase64(blob: any): Promise<string | ArrayBuffer> {
        return await new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    /**
     * @description Set state to Office context
     * @param  {string} fieldName
     * @param  {any} value
     * @returns void
     */
    setState(fieldName: string, value: any): void {
        Office.context.roamingSettings.set(fieldName, value);
    }

    /**
     * @description Get state from Office context
     * @param  {string} fieldName
     * @returns void
     */
    getState(fieldName: string): any {
        return Office.context.roamingSettings.get(fieldName);
    }

    /**
     * @description Get state from Office context
     * @param  {string} fieldName
     * @returns void
     */
    removeState(fieldName: string): any {
        Office.context.roamingSettings.remove(fieldName);
    }

    /**
     * @description Save state on Office context
     * @returns void
     */
    saveState(): void {
        Office.context.roamingSettings.saveAsync((asyncResult: { status: string; error: { message: string } }) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                console.error(STATE_CONSTANTS.SAVED_ERROR_MESSAGE(asyncResult?.error?.message ?? ''));
                return;
            }

            console.info(STATE_CONSTANTS.SAVED_MESSAGE);
        });
    }

    /**
     *
     * @description Check if email is from shared folder
     * @returns boolean
     */
    isFromSharedFolder(): boolean {
        return this.getOutlookInfo()?.isFromSharedMailbox ?? false;
    }

    /**
     *
     * @description Is available login with popup
     * @returns boolean
     */
    isAvailableLoginWithPopUP(): boolean {
        return this.isFromNewOutlook() || this.isFromOutlookWeb();
    }

    /**
     * @description Get opened hostName
     * @returns string
     */
    getHostName(): string {
        return Office.context.mailbox.diagnostics.hostName;
    }

    /**
     *
     * @private
     * @description Get shared properties from email
     * @returns void
     */
    getSharedProperties(): void {
        this._localStorageService.clearDelegatedEmail();

        if (!this.isFromSharedFolder()) {
            return;
        }

        Office.context.mailbox.getCallbackTokenAsync(
            { isRest: true },
            (asyncResult: { status: string; value: string; error?: { message: string } }) => {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded && asyncResult.value !== '') {
                    Office.context.mailbox.item.getSharedPropertiesAsync(
                        { asyncContext: asyncResult.value },
                        (asyncResult1: { value: ISharedProperties; error: { message: string }; status: string }) => {
                            if (asyncResult1.status === Office.AsyncResultStatus.Succeeded) {
                                const SHARED_PROPERTIES = asyncResult1.value;
                                this._localStorageService.setDelegatedEmail(SHARED_PROPERTIES.targetMailbox);
                            } else {
                                console.error(ERROR_MESSAGE.FAILED_NOT_GET_SHARED_PROPERTIES(asyncResult1.error.message));
                            }
                        }
                    );
                } else {
                    console.error(ERROR_MESSAGE.FAILED_NOT_GET_TOKEN_ON_SHARED_PROPERTIES(asyncResult.error?.message ?? ''));
                }
            }
        );
    }

    /**
     * @private
     * @description Get opened from outlook web APP
     * @returns boolean
     */
    private isFromOutlookWeb(): boolean {
        return this.getHostName() === OUTLOOK.HOSTNAME.WEB_APP;
    }

    /**
     * @private
     * @description Get opened from New Outlook Windows APP
     * @returns boolean
     */
    private isFromNewOutlook(): boolean {
        return this.getHostName() === OUTLOOK.HOSTNAME.NEW_OUTLOOK_WINDOWS;
    }

    /**
     * @private
     * @description Get opened from IOS APP
     * @returns boolean
     */
    private isFromIOSOutlook(): boolean {
        return this.getHostName() === OUTLOOK.HOSTNAME.IOS;
    }

    /**
     * @private
     * @description Get ItemId from email on non IOS platform
     * @returns string
     */
    private getItemRestIdFromNonIOSPlatform(): string {
        const ITEM_ID: string = Office?.context?.mailbox?.item?.itemId ?? '';
        if (!ITEM_ID?.length) {
            return '';
        }

        const REST_VERSION: string = Office?.MailboxEnums?.RestVersion.v2_0 ?? '';
        if (!REST_VERSION?.length) {
            return '';
        }

        return Office?.context?.mailbox.convertToRestId(ITEM_ID, REST_VERSION);
    }

    /**
     * @private
     * @description Get ItemId from email on IOS platform
     * @returns string
     */
    private getItemRestIdFromIOSPlatform(): string {
        return Office?.context?.mailbox?.item?.itemId ?? '';
    }
}
