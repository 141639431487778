export const PERMISSION = {
    VERPANELPRICE: 'VERPANELPRICE',
    VERMENUADMIN: 'VERMENUADMIN',
    VERPANELPROV: 'VERPANELPROV',
    VERMENUAUTO: 'VERMENUAUTO',
    DOCINCFAC: 'DOCINCFAC',
    VERROMOCEAN: 'VERROMOCEAN',
    VERINVOICINGBL: 'VERINVOICINGBL',
    VERINVOICINGSERVICE: 'VERINVOICINGSERVICE',
    VERMENUOPVFORWADING: 'VERMENUOPVFORWADING',
};
