import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentListComponent } from './components/document-list/document-list.component';
import { SpinnerLoadingComponent } from './components/spinner-loading/spinner-loading.component';
import { SessionInformationComponent } from './components/session-information/session-information.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [DocumentListComponent, SpinnerLoadingComponent, SessionInformationComponent],
    imports: [CommonModule, FormsModule],
    exports: [DocumentListComponent, SpinnerLoadingComponent, SessionInformationComponent],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
