<div class="container-fluid px-0">
    <div class="row d-flex justify-content-center mt-5">
        <div class="col-12 col-md-4 mt-5">
            <form [formGroup]="loginForm" class="row border rounded p-3 mb-3">
                <div class="row mb-2">
                    <div class="col-12">
                        <h5>Login</h5>
                    </div>
                </div>

                <div class="col-12 mb-2">
                    <div class="form-group">
                        <label for="userNameInput">Username</label>
                        <input
                            id="userNameInput"
                            type="text"
                            class="form-control form-control-sm text-muted"
                            placeholder="Enter username"
                            formControlName="userName"
                            autocomplete="off"
                            autofocus="autofocus"
                            tabindex="1"
                        />
                    </div>
                </div>

                <div class="col-12 mb-2">
                    <div class="form-group">
                        <label for="passwordInput">Password</label>
                        <input
                            id="passwordInput"
                            type="password"
                            class="form-control form-control-sm text-muted"
                            placeholder="Enter password"
                            formControlName="password"
                            autocomplete="off"
                            tabindex="2"
                        />
                    </div>
                </div>

                <div class="col-12 error_login_message">
                    <div
                        [ngClass]="{ 'show': errorLoginMessage && errorLoginMessage.length > 0 }"
                        class="d-block border rounded text-center py-1 px-2"
                    >
                        {{ errorLoginMessage }}
                    </div>
                </div>

                <div class="col-12 mt-4">
                    <div class="row">
                        <div class="col-12 col-sm-6 mb-2">
                            <button type="button" class="btn btn-sm btn-light text-primary w-100" (click)="loginForm.reset()">Clear</button>
                        </div>

                        <div class="col-12 col-sm-6 mb-2">
                            <button
                                type="submit"
                                class="btn btn-sm btn-primary w-100"
                                tabindex="3"
                                [disabled]="loginForm.pristine || loginForm.invalid"
                                (click)="login()"
                            >
                                Login
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
