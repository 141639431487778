import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ROUTES_DEFINITION } from 'src/app/constants/router-definitions.constant';

import { LocalStorageService } from '../../services/local-storage.service';
import { NotifyService } from '../../../core/services/notify.service';
import { HTTP_CONSTANTS } from 'src/app/constants/http.constants';
import { SessionStorageService } from '../../services/session-storage/session-storage.service';

@Injectable({
    providedIn: 'root',
})
export class HttpBaseInterceptor implements HttpInterceptor {
    constructor(
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _notifyService: NotifyService,
        private _sessionStorageService: SessionStorageService
    ) {}

    /**
     * @description Intercept all request on webApp and control errors
     * @param  {HttpRequest<any>} req
     * @param  {HttpHandler} next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: string = this._localStorageService.getToken();
        let request = req;

        if (!this.isMicrosoftGraphHeaderInHttpRequest(req)) {
            request = req.clone({
                setHeaders: {
                    Authorization: `${HTTP_CONSTANTS.AUTHORIZATION_BEARER} ${token}`,
                },
            });
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.url.toLowerCase().includes(HTTP_CONSTANTS.MICROSOFT_NAME_URL.toLowerCase())) {
                    console.error(err);
                    return throwError(err.error.error);
                }

                if (err.status === 401) {
                    this._localStorageService.clear();
                    this._router.navigate([ROUTES_DEFINITION.BASE]);
                }

                if (err.status === 400) {
                    this._sessionStorageService.logError(err.error.Messages[0].Message, null);
                    this._notifyService.error(err.error.Messages[0].Message);
                }

                if (err.status === 500) {
                    if (typeof err.error === 'string') {
                        this._sessionStorageService.logError(err.statusText, null);
                        this._notifyService.error(err.statusText);
                    } else {
                        if (err.error.hasOwnProperty('UserMessages')) {
                            this._sessionStorageService.logError(err.error.UserMessages[0].Message, null);
                            this._notifyService.error(err.error.UserMessages[0].Message);
                        }
                    }
                }

                console.error(err);
                return throwError(err);
            })
        );
    }

    /**
     *
     * @private
     * @description Get is microsoft graph request according authorization header
     * @param {HttpRequest<any>} req
     * @returns boolean
     */
    private isMicrosoftGraphHeaderInHttpRequest(req: HttpRequest<any>): boolean {
        return req.headers.keys().includes(HTTP_CONSTANTS.MICROSOFT_GRAPH_HEADER);
    }
}
