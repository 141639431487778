export class Division {
    constructor(
        public id: number,
        public groupDivisionId?: number,
        public companyDivisionId?: number,
        public description?: string,
        public currencyId?: number,
        public currencyCode?: string,
        public code?: string
    ) {}
}

export class DivisionTable {
    constructor(public tableId: number, public division: Division) {}
}

export class DivisionHelper {
    static mapToObject(result): Division {
        return new Division(
            result.Id,
            result.GroupDivisionId,
            result.CompanyDivisionId,
            result.Description,
            result.CurrencyId,
            result.CurrencyCode,
            result.Code
        );
        return;
    }
    static mapToObjectList(result): any[] {
        const items = [];
        const itemsToTransform = result.Data || result;
        itemsToTransform.forEach((item) => {
            items.push(this.mapToObject(item));
        });

        return items;
    }
}

export class DivisionTableHelper {
    static mapToObject(result): DivisionTable {
        return new DivisionTable(
            result.TableId,
            new Division(result.Division.DivisionId, null, null, result.Division.InfoDivisionDescription, null, null)
        );
    }
    static mapToObjectList(result): any[] {
        const items = [];
        const itemsToTransform = result.Data || result;
        itemsToTransform.forEach((item) => {
            items.push(this.mapToObject(item));
        });

        return items;
    }
}
