import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/core/auth/services/auth.service';

import { LoginComponent } from 'src/app/modules/login/login.component';

const routes: Routes = [
    { path: 'login', component: LoginComponent, resolve: [AuthService] },
    {
        path: 'attachment',
        loadChildren: () => import('./modules/attachment/attachment.module').then((m) => m.AttachmentModule),
    },
    {
        path: 'microsoft',
        loadChildren: () => import('./modules/microsoft-login/microsoft-login.module').then((m) => m.MicrosoftLoginModule),
    },
    { path: 'code', redirectTo: 'microsoft/login-success' },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            useHash: true,
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
