import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private endpoints = {
        getPermissions: environment.shippingApi + '/api/UserPermission/GetPermissionsByUserPrincipal',
    };

    constructor(private http: HttpClient) {}

    /**
     * @description Used for get the permissions of the current user
     * @returns {Observable<string[]>}
     */
    getPermissions(): Observable<string[]> {
        return this.http
            .post(this.endpoints.getPermissions, null)
            .pipe(map((result: any) => result.Data.map((permissions: { Code: string }) => permissions.Code)));
    }
}
