export const ERROR_MESSAGE = {
    // GENERAL TYPE
    COULD_NOT_GET_GENERAL_TYPE_FOR_ENTITIES: 'Could not get General type for entities',

    // ADD ATTACHMENT
    COULD_NOT_GET_DOCUMENT_FROM_DOCUMENT_MANAGER: 'Could not get document from document manager',

    // EXPORT ATTACHMENT
    COULD_NOT_GET_EMAIL: 'Could not get email',
    COULD_NOT_GET_EMAIL_ID: 'Could not get email id',
    COULD_NOT_GET_DOCUMENTS_FROM_EMAIL: 'Could not get Documents from email',
    FAILED_TO_UPLOAD_DOCUMENTS: 'Failed to upload Documents',

    FAILED_NOT_GET_TOKEN_ON_SHARED_PROPERTIES: (errorMessage: string): string => {
        return `Failed to get callback token: ${errorMessage}`;
    },
    FAILED_NOT_GET_SHARED_PROPERTIES: (errorMessage: string): string => {
        return `Failed to get shared properties: ${errorMessage}`;
    },
};
