import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { ENTITY_TABLE_NAME_PATTERN, VFORWARDING_CODE_PATTERN } from '../../constants/pattern.constants';

@Injectable({
    providedIn: 'root',
})
export class PatternService {
    entityPattern = ENTITY_TABLE_NAME_PATTERN;
    vForwardingPattern = VFORWARDING_CODE_PATTERN;

    constructor() {}

    /**
     * @description match entity code on text
     * @param  {string} labelToMatch
     * @returns string
     */
    mathEntityPattern(labelToMatch: string): string | null {
        for (var [tableName, regex] of Object.entries(this.entityPattern)) {
            if (Array.isArray(regex)) {
                for (let pattern of regex.map((r) => new RegExp(r))) {
                    if (pattern.test(labelToMatch)) {
                        return tableName;
                    }
                }
            } else {
                if (new RegExp(regex).test(labelToMatch)) {
                    return tableName;
                }
            }
        }

        return null;
    }

    /**
     * @description Find entity code on text
     * @param  {string} text
     * @returns string
     */
    findEntityOnText(text: string): { entityCode: string; tableName: string } | null {
        let entityPatterns: any[] = Object.entries(this.entityPattern);

        for (var [tableName, regex] of entityPatterns) {
            if (Array.isArray(regex)) {
                for (let pattern of regex.map((r) => new RegExp(r.split('^')[1], 'g'))) {
                    const matchList: any[] = Array.from(text.matchAll(pattern));
                    if (matchList.length > 0) {
                        return { entityCode: matchList[0][0], tableName: tableName };
                    }
                }
            } else {
                const match: any = text.match(new RegExp(regex.split('^')[1], 'g'));
                if (match !== null) {
                    return { entityCode: match[0], tableName: tableName };
                }
            }
        }

        return null;
    }

    /**
     * @description Find entity code on text
     * @param  {string} text
     * @returns string
     */
    findvForwardingCodeOnText(text: string): string | null {
        let entityPatterns: any[] = Object.values(this.vForwardingPattern);

        for (var regex of entityPatterns) {
            const match: any = text.match(new RegExp(regex.split('^')[1], 'g'));
            if (match !== null) {
                return match[0];
            }
        }

        return null;
    }
}
