import { Injectable } from '@angular/core';

import { MessageType } from '../../../app/shared/enum/message.enum';

import { GlobalConfig, IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class NotifyService {
    individualOptions: IndividualConfig;

    constructor(
        private _toastr: ToastrService
    ) {
    }

    /**
     * @description Generate push info notity
     * @param  {string} message
     */
    info(message: string, title?: string): void {
        this._toastr.info(message, title ? title : '', { toastClass: 'ngx-toastr notify-info' })
    }

    /**
     * @description Generate push success notity
     * @param  {string} message
     */
    success(message: string, title?: string): void {
        this._toastr.success(message, title ? title : '', { toastClass: 'ngx-toastr notify-success' });
    }

    /**
     * @description Generate push error notity
     * @param  {string} message
     */
    error(message: string, title?: string): void {
        this._toastr.error(message, title ? title : '', { toastClass: 'ngx-toastr notify-error' })
    }

    /**
     * @description Generate push warning notity
     * @param  {string} message
     */
    warning(message: string, title?: string): void {
        this._toastr.warning(message, title ? title : '', { toastClass: 'ngx-toastr notify-warning' })
    }

    /**
     * @description Manage message type and push notify
     * @param  {string} message
     */
    message(response: any): boolean {
        if (response.hasOwnProperty('Message') && response.hasOwnProperty('MessageType')) {
            switch (response.MessageType) {
                case MessageType.Info:
                    this.info(response.Message);
                    break;
                case MessageType.Success:
                    this.success(response.Message);
                    break;
                case MessageType.Warning:
                    this.warning(response.Message);
                    break;
                case MessageType.Error:
                    this.error(response.Message);
                    break;
                default:
                    break;
            }
            return true;
        } else if (response.hasOwnProperty('Messages')) {
            if (response.Messages.length > 0) {
                
            for (const message of response.Messages) {
                this.message(message);
            }
            return true;
            }
        }

        return false;
    } 

    /**
    * @description Close all notifies
    */
    removeAll(): void {
        this._toastr.clear();
    }

    /**
    * @description Close Last open notify
    */
    removeLast(): void {
        this._toastr.clear(this._toastr.toasts[this._toastr.toasts.length - 1].toastId)
    }
}
