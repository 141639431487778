import { Injectable } from '@angular/core';
import { LocalStorageService } from '../services/local-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OutlookService } from '../services/outlook.service';
import { NotifyService } from '../services/notify.service';
import { IMicrosoftGraphAttachResponse } from 'src/app/shared/models/microsoft-graph/microsoft-graph-attach.model';
import { OutlookFileInfo, OutlookFileInfoHelper, OutlookUserEmailInfo } from 'src/app/shared/models/outlook.model';
import { MICROSOFT_GRAPH_CONSTANTS } from 'src/app/constants/microsoft-graph.constant';
import {
    IMicrosoftGraphUserResponse,
    IMicrosoftGraphUserSimple,
    MicrosoftGraphUserHelper,
} from 'src/app/shared/models/microsoft-graph/microsoft-graph-user.model';
import { HTTP_CONSTANTS } from 'src/app/constants/http.constants';
import { SessionStorageService } from '../services/session-storage/session-storage.service';
import { catchError, delayWhen, map, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, throwError, timer } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionRequiredAuthError, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MicrosoftGraphService {
    constructor(
        private _http: HttpClient,
        private _localStorageService: LocalStorageService,
        private _outlookService: OutlookService,
        private _notifyService: NotifyService,
        private _sessionStorageService: SessionStorageService,
        private _msalAuthService: MsalService
    ) {}

    /**
     * @description Call Microsoft Graph API to get the email list
     * @return Observable<IMicrosoftGraphUserSimple | null>
     */
    getToEmailListFromMicrosoftGraph(): Observable<IMicrosoftGraphUserSimple | null> {
        if (!this._outlookService.getOutlookInfo().isFromSharedMailbox) {
            return of(null);
        }

        const EMAIL_LIST: string[] = [
            ...this._outlookService.getTo().map((email: OutlookUserEmailInfo) => email.email),
            this._outlookService.getFrom().email,
        ];
        if (!EMAIL_LIST?.length) {
            return of(null);
        }

        return this.requestWithRetry(EMAIL_LIST);
    }

    /**
     * @description Refresh the Microsoft Graph access token
     * @return Observable<void>
     */
    refreshToken(): Observable<void> {
        const REDIRECT_REQUEST: RedirectRequest = {
            scopes: environment.apiConfig.scopes,
            account: this._msalAuthService.instance.getActiveAccount(),
        };

        this._localStorageService.clearMicrosoftGraphItems();
        return new Observable<void>((observer) => {
            this._msalAuthService.instance
                .acquireTokenSilent(REDIRECT_REQUEST)
                .then(() => {
                    console.info(MICROSOFT_GRAPH_CONSTANTS.TOKEN_REFRESH_SUCCESSFULLY);
                    observer.next();
                    observer.complete();
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        this._msalAuthService.instance.acquireTokenRedirect(REDIRECT_REQUEST);
                        console.info(MICROSOFT_GRAPH_CONSTANTS.TOKEN_REFRESH_FAILED_NEED_LOGIN);
                    } else {
                        console.error(MICROSOFT_GRAPH_CONSTANTS.TOKEN_REFRESH_FAILED, error);
                        observer.error(error);
                    }
                });
        });
    }

    /**
     * @description Clear cache from MSAL
     * @returns void
     */
    clearCache(): void {
        this._msalAuthService.instance.clearCache();
    }

    /**
     * @description Perform HTTP request with retry mechanism
     * @param EMAIL_LIST Array of email addresses
     * @return Observable<IMicrosoftGraphUserSimple | null>
     */
    private requestWithRetry(EMAIL_LIST: string[]): Observable<IMicrosoftGraphUserSimple | null> {
        let requestAttempts = 0;
        const ENDPOINT: string = MICROSOFT_GRAPH_CONSTANTS.GET_TO_EMAIL_LIST_ENDPOINT(EMAIL_LIST.join(`','`));

        return of(null).pipe(
            switchMap(() => {
                requestAttempts++;
                console.info(MICROSOFT_GRAPH_CONSTANTS.ATTEMPT_CALL_ENDPOINT(requestAttempts, ENDPOINT));
                const headers = this.getHeadersWithAccessToken();
                return this._http.get<IMicrosoftGraphUserResponse>(ENDPOINT, { headers });
            }),
            catchError((error) => {
                if (requestAttempts < 3) {
                    console.warn(MICROSOFT_GRAPH_CONSTANTS.RETRY_CALL_ENDPOINT(ENDPOINT));
                    return throwError(error);
                } else {
                    throw error;
                }
            }),
            retryWhen((errors) =>
                errors.pipe(
                    tap(() => console.warn(MICROSOFT_GRAPH_CONSTANTS.RETRY_CALL_ENDPOINT(ENDPOINT))),
                    delayWhen(() => timer(2000)),
                    take(2) // Take 2 retries, making a total of 3 attempts
                )
            ),
            map((response: IMicrosoftGraphUserResponse) => {
                if (!response.value?.length) {
                    return null;
                }

                const FIRST_DELEGATED_EMAIL: IMicrosoftGraphUserSimple = MicrosoftGraphUserHelper.mapToDelegatedEmailToList(response.value)[0];
                if (!FIRST_DELEGATED_EMAIL) {
                    return null;
                }

                return FIRST_DELEGATED_EMAIL;
            }),
            catchError((error) => {
                this._sessionStorageService.logError(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_EMAIL_FROM_MICROSOFT_CLOUD, error);
                return of(null);
            })
        );
    }

    /**
     * @description Call Microsoft Graph API to get attachment content by attachment ID
     * @return Promise<OutlookFileInfo[]>
     */
    async getAttachedFilesFromMicrosoftGraph(): Promise<OutlookFileInfo[]> {
        const ENDPOINT: string = this.getAttachedFilesFromMicrosoftGraphEndpoint();
        if (!ENDPOINT?.length) {
            return [];
        }

        try {
            const ATTACH_FILE_LIST: OutlookFileInfo[] = await this._http
                .get(ENDPOINT, {
                    headers: this.getHeadersWithAccessToken(),
                })
                .toPromise()
                .then((response: IMicrosoftGraphAttachResponse) => {
                    return OutlookFileInfoHelper.mapToObjectList(response.value);
                })
                .catch((error) => {
                    this._sessionStorageService.logError(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_ATTACHED_FILES_FROM_MICROSOFT_CLOUD, error);
                    return [];
                });

            return ATTACH_FILE_LIST;
        } catch (error) {
            this._sessionStorageService.logError(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_ATTACHED_FILES_FROM_MICROSOFT_CLOUD, error);
            this._notifyService.error(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_ATTACHED_FILES_FROM_MICROSOFT_CLOUD);
            return [];
        }
    }

    /**
     * @description Call Microsoft Graph API to get current email content
     * @returns string | void
     */
    async getEmailFromMicrosoftGraph(): Promise<string | void> {
        const ENDPOINT: string = this.getEmailFromMicrosoftGraphEndpoint();
        if (!ENDPOINT?.length) {
            return '';
        }

        try {
            const BASE_64_EMAIL: string | void = await this._http
                .get(ENDPOINT, {
                    headers: this.getHeadersWithAccessToken(),
                    responseType: 'blob',
                })
                .toPromise()
                .then((blob) => {
                    return this._outlookService.convertEmailToBase64(blob);
                })
                .then((base64) => {
                    const BASE_64_EMAIL: string = base64.toString().split(',')[1];
                    return BASE_64_EMAIL ?? '';
                })
                .catch((error) => {
                    this._sessionStorageService.logError(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_BASE_64, error);
                    this._notifyService.error(error);
                });

            return BASE_64_EMAIL;
        } catch (error) {
            this._sessionStorageService.logError(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_EMAIL_FROM_MICROSOFT_CLOUD, error);
            this._notifyService.error(MICROSOFT_GRAPH_CONSTANTS.COULD_NOT_GET_EMAIL_FROM_MICROSOFT_CLOUD);
            return '';
        }
    }

    /**
     *
     * @private
     * @description Get Attached Files from Microsoft Graph Endpoint
     * @returns string
     */
    private getEmailFromMicrosoftGraphEndpoint(): string {
        const EMAIL_ID: string = this._outlookService.getItemRestId();
        if (!EMAIL_ID?.length) {
            return '';
        }

        const IS_FROM_SHARED_MAILBOX: boolean = this._outlookService.getOutlookInfo()?.isFromSharedMailbox;
        if (!IS_FROM_SHARED_MAILBOX) {
            return MICROSOFT_GRAPH_CONSTANTS.GET_EMAIL_FROM_ME_ENDPOINT(EMAIL_ID);
        }

        const DELEGATED_EMAIL: string = this._localStorageService.getDelegatedEmail();
        if (!!DELEGATED_EMAIL?.length) {
            return MICROSOFT_GRAPH_CONSTANTS.GET_EMAIL_FROM_USERS_ENDPOINT(DELEGATED_EMAIL, EMAIL_ID);
        }

        return '';
    }

    /**
     *
     * @private
     * @description Get Attached Files from Microsoft Graph Endpoint
     * @returns string
     */
    private getAttachedFilesFromMicrosoftGraphEndpoint(): string {
        const EMAIL_ID: string = this._outlookService.getItemRestId();
        if (!EMAIL_ID?.length) {
            return '';
        }

        const IS_FROM_SHARED_MAILBOX: boolean = this._outlookService.getOutlookInfo()?.isFromSharedMailbox;
        if (!IS_FROM_SHARED_MAILBOX) {
            return MICROSOFT_GRAPH_CONSTANTS.GET_ATTACH_FILES_LIST_FROM_ME_ENDPOINT(EMAIL_ID);
        }

        const DELEGATED_EMAIL: string = this._localStorageService.getDelegatedEmail();
        if (!!DELEGATED_EMAIL?.length) {
            return MICROSOFT_GRAPH_CONSTANTS.GET_ATTACH_FILES_LIST_FROM_USERS_ENDPOINT(DELEGATED_EMAIL, EMAIL_ID);
        }

        return '';
    }

    /**
     *
     * @private
     * @description Get headers with microsoft graph access token
     * @returns HttpHeaders
     */
    private getHeadersWithAccessToken(): HttpHeaders {
        const MICROSOFT_GRAPH_ACCESS_TOKEN = this._localStorageService.getMicrosoftGraphAccessToken()?.secret ?? '';
        return new HttpHeaders({
            [HTTP_CONSTANTS.AUTHORIZATION_HEADER]: `${HTTP_CONSTANTS.AUTHORIZATION_BEARER} ${MICROSOFT_GRAPH_ACCESS_TOKEN}`,
            [HTTP_CONSTANTS.MICROSOFT_GRAPH_HEADER]: 'true',
        });
    }
}
