<div class="container-fluid px-0">
    <div class="row pb-2 d-flex align-items-center justify-content-between" *ngIf="userName$ | async as userName">
        <div class="col-6">
            <div class="d-flex justify-content-start align-items-center">
                <span class="icon material-icons-two-tone filter-secodary ml-2"> person </span>
                <span class="username text-muted mx-2">{{ userName }}</span>
            </div>
        </div>
        <div class="col-6 d-flex justify-content-end align-items-center">
            <button class="btn btn-sm btn-light text-danger w-sm-100" (click)="logout()">
                <span class="icon material-icons-two-tone"> logout </span>
                Logout
            </button>
        </div>
    </div>
</div>
