import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MESSAGE } from 'src/app/constants/message.constant';

import { OUTLOOK } from 'src/app/constants/outlook.constant';
import { DocumentationService } from 'src/app/core/http-services/documentation/documentation.service';
import { AutocompleteService } from 'src/app/core/services/autocomplete.service';

import { EventService } from 'src/app/core/services/event.service';
import { NotifyService } from 'src/app/core/services/notify.service';

import { DocDownload } from 'src/app/shared/models/docs/docDownload.model';
import { DocumentListConfiguration } from 'src/app/shared/models/docs/document-list.config.model';
import { ServiceTypeDocument } from 'src/app/shared/models/docs/document.model';
import { SelectDocumentType } from '../../enum/document.enum';

declare const $: any;

@Component({
    selector: 'document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
    @Input() set _documentListConfiguration(_documentListConfiguration: DocumentListConfiguration) {
        if (this.documentListConfiguration !== _documentListConfiguration) {
            this.documentListConfiguration = _documentListConfiguration;
            this.initComponent();
        }
    }

    @Input() set _uploadingEmail(_uploadingEmail: boolean) {
        this.uploadingEmail = _uploadingEmail;
    }

    @Input() set _uploadingFiles(_uploadingFiles: boolean) {
        this.uploadingFiles = _uploadingFiles;
    }

    @Output() _getDocument = new EventEmitter<DocDownload>();
    @Output() _getDocumentList = new EventEmitter<DocDownload[]>();

    uploadingEmail: boolean = false;
    uploadingFiles: boolean = false;

    allSelected: boolean = false;
    selectedDocuments: DocDownload[] = [];
    selectDocumentInputId = 'searchDocumentType';

    documentListConfiguration: DocumentListConfiguration | null = null;
    documentList: DocDownload[] = [];
    emailAsDocument: DocDownload;

    filteredTypeDocumentList: ServiceTypeDocument[] = [];
    filteredTypeDocumentOnDocumentId: number = 0;

    documentListSubscription$: Subscription | undefined = null;

    public selectDocumentType = SelectDocumentType;
    outlookConstants = OUTLOOK;

    constructor(
        public _autocompleteService: AutocompleteService,
        private _notifyService: NotifyService,
        private _eventService: EventService,
        private _documentationService: DocumentationService
    ) {}

    ngOnInit(): void {
        this.initComponent();
    }

    initComponent(): void {
        this.allSelected = false;
        this.selectedDocuments = [];

        this.documentList = this.documentListConfiguration.documentList.filter((document) => document.extension !== null);
        this.emailAsDocument = this.documentListConfiguration.documentList.find((document) => document.extension === null);

        $(function () {
            $("[data-toggle='tooltip']").tooltip();
        });
    }

    /**
     * @description Prevent select all documents
     * @returns void
     */
    preventSelectAllDocuments(): void {
        const EXIST_DOCUMENT_WITHOUT_EMAIL: boolean = this.getDocumentListWithoutEmail() && this.getDocumentListWithoutEmail().length > 0;
        if (!EXIST_DOCUMENT_WITHOUT_EMAIL) {
            return;
        }

        if (this.documentListConfiguration.selectDocumentType === SelectDocumentType.autocompleteByCompanyDivisionAndEntityType) {
            if (this.getDocumentListWithoutEmail().every((document) => document.typeId !== null)) {
                this.selectAllDocuments();
                return;
            } else {
                this._notifyService.warning(MESSAGE.SELECTED_ALL_REQUIRES_EVERY_ONE);
                return;
            }
        }

        if (this.documentListConfiguration.selectDocumentType === SelectDocumentType.textFree) {
            this.selectAllDocuments();
            return;
        }
    }

    /**
     * @description Manage selected/unselected all document on list
     * @returns void
     */
    selectAllDocuments(): void {
        if (!this.allSelected) {
            this.selectedAllDocuments();
        } else {
            this.unselectedAllDocuments();
        }
    }

    /**
     * @description Selected all document on list
     * @returns void
     */
    selectedAllDocuments(): void {
        this.getDocumentListWithoutEmail().map((mapDocument) => (mapDocument.selected = true));
        this.selectedDocuments = this.getDocumentListWithoutEmail().filter((mapDocument) => (mapDocument.selected = true));
        this.allSelected = this.getDocumentListWithoutEmail().length === this.selectedDocuments.length;
    }

    /**
     * @description Unselected all document on list
     * @returns void
     */
    unselectedAllDocuments(): void {
        this.getDocumentListWithoutEmail().map((mapDocument) => (mapDocument.selected = false));
        this.selectedDocuments = [];
        this.allSelected = false;
    }

    /**
     * @description Manage data when select actions
     * @param  {DocDownload} document
     * @returns void
     */
    selectDocument(document: DocDownload): void {
        if (!document && !document?.id) {
            return;
        }

        const SELECTED_DOCUMENT_TYPE: boolean = this.preventSelectedDocumentType(document);

        if (!SELECTED_DOCUMENT_TYPE) {
            return;
        }

        if (this.selectedDocuments.find((findDocument) => findDocument.id === document.id)) {
            this.selectedDocuments = this.selectedDocuments.filter((filterDocument) => filterDocument.id !== document.id);
            this.allSelected = false;
        } else {
            this.selectedDocuments.push(document);
        }

        document.selected = !document.selected;
    }

    /**
     * @description Get all document without email
     * @returns DocDownload[]
     */
    getDocumentListWithoutEmail(): DocDownload[] {
        if (this.emailAsDocument !== undefined) {
            return this.documentListConfiguration.documentList.filter((document) => document.id !== this.emailAsDocument.id);
        }
        return this.documentListConfiguration.documentList;
    }

    /**
     * @description Call service and get type documents for display autocomplete results
     * @param  {{ target: { value: string } }} event
     * @param  {DocDownload} document
     * @returns void
     */
    getTypeDocuments(event: { target: { value: string } }, document: DocDownload): void {
        this.filteredTypeDocumentOnDocumentId = Number(document.id);
        const SEARCH: string = event.target.value;
        if (this._eventService.isKeyPressArrow(event)) {
            return;
        }

        if (typeof SEARCH === 'string' && SEARCH.length >= 2) {
            this._autocompleteService.controlSetTimeOutAutocomplete(() => {
                this.documentListSubscription$ = this._documentationService
                    .getDocumentsByCompanyDivision(SEARCH, document.companyDivisionId, this.documentListConfiguration.entityType)
                    .subscribe(
                        (response: ServiceTypeDocument[]) => {
                            if (!response) {
                                this._notifyService.warning(MESSAGE.COULD_NOT_GET_TYPE_DOCUMENTS);
                            } else {
                                if (response.length > 0) {
                                    this.filteredTypeDocumentList = [...new Set(response)];
                                } else {
                                    this._notifyService.warning(MESSAGE.NO_EXISTS_TYPE_DOCUMENTS);
                                }
                            }
                            this.documentListSubscription$.unsubscribe();
                        },
                        (error) => {
                            if (error) {
                                this._notifyService.error(MESSAGE.COULD_NOT_GET_TYPE_DOCUMENTS);
                            }
                            this.documentListSubscription$?.unsubscribe();
                        }
                    );
            }, 200);
        } else {
            this.filteredTypeDocumentList = [];
        }
    }

    /**
     * @description Select document type on autocomplete list
     * @param {ServiceTypeDocument} typeDocument
     * @param {DocDownload} doc
     * @returns void
     */
    selectTypeDocument(typeDocument: ServiceTypeDocument, doc: DocDownload): void {
        if (typeDocument && doc) {
            doc.typeId = typeDocument.typeDocumentId;
            doc.type = typeDocument.code;
            doc.typeDescription = typeDocument.description;
            doc.isUnique = typeDocument.isUnique;

            this.filteredTypeDocumentList = [];
            this.filteredTypeDocumentOnDocumentId = 0;
            (<HTMLInputElement>document.getElementById(this.selectDocumentInputId + doc.id)).value = '';
        }
    }

    /**
     * @description Execute action on document list
     * @returns void
     */
    executeActionDocumentList(): void {
        let uniqueDocList = this.documentList.filter((doc) => doc.isUnique);
        let oneMoreUniqueSameTypeDocument = [...new Set(uniqueDocList.map((doc) => doc.typeId))].length === uniqueDocList.length;

        if (oneMoreUniqueSameTypeDocument) {
            if (this.getDocumentListWithoutEmail() && this.selectedDocuments.length > 0) {
                for (const DOCUMENT of this.selectedDocuments) {
                    this.emitGetDocument(DOCUMENT);
                }

                this._notifyService.success(MESSAGE.APPLY_ON_NUMBER_OF_DOCUMENTS(this.selectedDocuments.length));
                this.unselectedAllDocuments();
            }
        } else {
            this._notifyService.warning(MESSAGE.NOT_UPLOAD_MORE_THAN_ONE_DOCUMENT_WITH_A_SINGLE_DOCUMENT_TYPE);
        }
    }

    /**
     * @description Upload Email document
     * @returns void
     */
    uploadEmail(): void {
        const SELECTED_DOCUMENT_TYPE: boolean = this.preventSelectedDocumentType(this.emailAsDocument);

        if (!SELECTED_DOCUMENT_TYPE) {
            return;
        }

        this.emitGetDocument(this.emailAsDocument);
    }

    /**
     * @description Prevent selected document type on line
     * @returns boolean
     */
    preventSelectedDocumentType(document: DocDownload): boolean {
        if (this.documentListConfiguration.selectDocumentType === SelectDocumentType.autocompleteByCompanyDivisionAndEntityType) {
            if (!document.typeId) {
                this._notifyService.warning(MESSAGE.YOU_NEED_A_DOCUMENT_TYPE_SELECTED);
                return false;
            }
        }

        if (this.documentListConfiguration.selectDocumentType === SelectDocumentType.textFree) {
            if (!document?.type?.trim().length) {
                this._notifyService.warning(MESSAGE.YOU_NEED_A_ENVIOS_ID);
                return false;
            }
        }

        return true;
    }

    /**
     * @description Manage lost focus on autocomplete search document type
     * @returns void
     */
    lostFocusTypeDocumentAutocomplete(): void {
        this._autocompleteService.controlSetTimeOutAutocomplete(() => {
            this.filteredTypeDocumentList = [];
        }, 200);
    }

    /**
     * @description Emit event when get document
     * @param  {DocDownload} document
     */
    emitGetDocument(document: DocDownload): void {
        this._getDocument.emit(document);
    }

    /**
     * @description Emit event when get document list
     * @param  {DocDownload[]} documentList
     * @returns void
     */

    emitGetDocumentList(documentList: DocDownload[]): void {
        this._getDocumentList.emit(documentList);
    }
}
