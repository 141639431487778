import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { LOCAL_STORAGE } from 'src/app/constants/localStorage.constant';

import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { OutlookService } from '../../services/outlook.service';
import { UserService as HttpUserService } from '../../http-services/user/user.service';
import { PermissionService } from '../../services/permission/permission.service';
import { UserService } from './user/user.service';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private endpoints = {
        token: environment.validationApi + '/token',
    };

    private _apiUrl = this.endpoints.token;

    constructor(
        private _http: HttpClient,
        private _localStorageService: LocalStorageService,
        private _injector: Injector,
        private _userService: UserService,
        private _httpUserService: HttpUserService,
        private _permissionService: PermissionService
    ) {}

    /**
     * @description Call API and get valid token and save on LocalStorage
     * @param  {string} userName
     * @param  {string} password
     * @returns Observable<string>
     */
    getToken(userName: string, password: string): Observable<string> {
        const tokenData = {
            'login': userName,
            'password': password,
            'appID': environment.appId,
        };

        return this._http.post(this._apiUrl, tokenData).pipe(
            map((token: string) => {
                const _OUTLOOK_SERVICE: OutlookService = this._injector.get(OutlookService);
                const NEW_USER_NAME: string = userName.trim();

                this._localStorageService.setUserName(NEW_USER_NAME);
                this._localStorageService.setToken(token);

                _OUTLOOK_SERVICE.setState(LOCAL_STORAGE.USER_NAME, NEW_USER_NAME);
                _OUTLOOK_SERVICE.setState(LOCAL_STORAGE.TOKEN, token);
                _OUTLOOK_SERVICE.saveState();

                this._userService.userNameObservableData(NEW_USER_NAME);
                this._httpUserService.getPermissions().subscribe((permissionList: string[]) => {
                    this._permissionService.setPermissionList(permissionList);
                });

                return token;
            })
        );
    }

    /**
     * @description Call API and update token and update on LocalStorage
     * @param  {string} token
     */
    updateToken(token: string) {
        return this._http
            .patch(this._apiUrl, undefined)
            .toPromise()
            .then((response) => {
                const _outlookService = this._injector.get(OutlookService);
                this._localStorageService.setToken(token);
                _outlookService.setState(LOCAL_STORAGE.TOKEN, token);
                _outlookService.saveState();
                return response;
            });
    }

    /**
     * @description Call API and force expired token
     */
    deleteToken() {
        return this._http.delete(this._apiUrl);
    }
}
