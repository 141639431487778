import { Division } from '../division.model';

export class ServiceTypeDocument {
    constructor(
        public id?: number,
        public code?: string,
        public description?: string,
        public divisionId?: number,
        public division?: Division,
        public isUnique?: boolean,
        public mandatory?: boolean,
        public typeDocumentId?: number,
        public typeDocumentDivisionId?: number,
        public serviceTypeId?: number,
    ) { }
}

export class ServiceTypeDocumentFilter {
    constructor(
        public serviceTypeId: number,
        public companyDivisionId?: number,
    ) { }
}

export class ServiceTypeDocumentHelper {
    static mapToObject(result): ServiceTypeDocument {
        return new ServiceTypeDocument(
            result.ID !== undefined ? result.ID : result.Id,
            result.Code,
            result.Description,
            result.DivisionID !== undefined ? result.DivisionID : result.DivisionId,
            result.Division,
            result.IsUnique,
            result.Mandatory,
            result.TypeDocumentID !== undefined ? result.TypeDocumentID : result.TypeDocumentId,
            result.TypeDocumentDivisionID !== undefined ? result.TypeDocumentDivisionID : result.TypeDocumentDivisionId,
            result.ServiceTypeId
        );
    }

    static mapToObjectList(result): any[] {
        if (result === undefined || result === null) {
            return [];
        } else {
            const items = [];
            const itemsToTransform = result.Data ?? result;
            itemsToTransform.forEach(item => {
                items.push(this.mapToObject(item));
            });
    
            return items;
        }
    }
}