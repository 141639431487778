import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare const Office: any;
declare const $: any;

if (environment.production) {
    enableProdMode();
}

if (environment.web) {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
} else {
    Office.initialize = () => {
        platformBrowserDynamic()
            .bootstrapModule(AppModule)
            .catch((err) => console.error(err));

        $(function () {
            $("[data-toggle='tooltip']").tooltip();
        });
    };
}
