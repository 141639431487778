export interface IMicrosoftGraphUserResponse {
    value: IMicrosoftGraphUser[];
}

export interface IMicrosoftGraphUserSimple {
    mail: string;
    id: string;
}

export interface IMicrosoftGraphUser extends IMicrosoftGraphUserSimple {
    businessPhones: string[];
    displayName: string;
    givenName: string;
    jobTitle: string | null;
    mobilePhone: string | null;
    officeLocation: string | null;
    preferredLanguage: string | null;
    surname: string;
    userPrincipalName: string;
}

export class MicrosoftGraphUserHelper {
    static mapToDelegatedEmailToList(toEmailList: IMicrosoftGraphUser[]): IMicrosoftGraphUserSimple[] {
        return toEmailList
            .filter((emailTo: IMicrosoftGraphUser) => {
                return (
                    !emailTo.jobTitle?.length &&
                    !emailTo.mobilePhone &&
                    !emailTo.officeLocation?.length &&
                    !emailTo.preferredLanguage?.length &&
                    !emailTo.displayName.includes(emailTo.givenName) &&
                    !emailTo.displayName.includes(emailTo.surname)
                );
            })
            .map((emailTo: IMicrosoftGraphUser) => {
                return {
                    mail: emailTo.mail,
                    id: emailTo.id,
                };
            });
    }
}
