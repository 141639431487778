import { IEnvironment } from './environment.interface';
import { environment as productionEnvironment } from './environment.prod';

export const environment: IEnvironment = {
    production: false,
    web: false,
    appId: 3,
    validationApi: 'https://app-shippingauth-pre-002.azurewebsites.net',
    shippingApi: 'https://app-shippingapi-pre-002.azurewebsites.net',
    documentShippingApi: 'https://pre-docs-api.romeushipping.com/api/v1',
    webUrl: 'https://pre-m365-app.romeushipping.com/',
    msalConfig: productionEnvironment.msalConfig,
    apiConfig: productionEnvironment.apiConfig,
};
