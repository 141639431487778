export const LOCAL_STORAGE = {
    // GLOBAL
    USER_NAME: 'userName',
    TOKEN: 'token',
    NAVIGATION: 'navigation',
    PERMISSIONS: 'permissions',
    LANGUAGE_LIST: 'lib_languageList',
    TRAFFIC_IST: 'lib_trafficList',
    CURRENCY_LIST: 'lib_currencyList',
    COUNTRY_LIST: 'lib_countryList',
    TYPE_DOCUMENT_DIVISION_LIST: 'lib_typeDocumentDivisionList',
    ENTITY_TYPE_LIST: 'lib_entityTypeList',
    USER_DIVISION_LIST: 'lib_userDivisionList',
    GENERAL_TYPE_LIBRARY_LIST: 'lib_generalTypeLibraryList',
    CALCULATION_METHOD_LIST: 'lib_calculationMethodList',
    SEGMENT_TYPE_LIST: 'lib_segmentTypeList',
    MICROSOFT: {
        PARTIAL_ACCESS_TOKEN: 'login.windows.net-accesstoken',
        PARTIAL_REFRESH_TOKEN: 'login.windows.net-refreshtoken',
        PARTIAL_SYSTEM_INFORMATION: 'login.windows.net',
    },
    DELEGATED_EMAIL: 'delegatedEmail',
    APP_ERRORS: 'appErrors',
};
