import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ROUTES_DEFINITION } from 'src/app/constants/router-definitions.constant';
import { LOCAL_STORAGE } from 'src/app/constants/localStorage.constant';

import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { TokenService } from './token.service';
import { OutlookService } from '../../services/outlook.service';
import { PermissionService } from '../../services/permission/permission.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private _router: Router,
        private _localStorageService: LocalStorageService,
        private _tokenService: TokenService,
        private _outlookService: OutlookService,
        private _permissionService: PermissionService
    ) {}

    /**
     * @description When exist valid token navigate to app
     * @returns void
     */
    resolve(): void {
        const TOKEN = this._localStorageService.getToken();
        if (TOKEN) {
            this._router.navigate([ROUTES_DEFINITION.BASE]);
        }
    }

    /**
     * @description Call API and login with credentials and save receive token on localStorage
     * @param  {string} userName
     * @param  {string} password
     * @returns Observable<string>
     */
    login(userName: string, password: string): Observable<string> {
        return this._tokenService.getToken(userName, password);
    }

    /**
     * @description Check exist valid token
     * @returns {boolean}
     */
    checkIsLogged(): boolean {
        const TOKEN = this._localStorageService.getToken();
        return !!TOKEN?.length;
    }

    /**
     * @description Call API and force expire token
     * @returns void
     */
    logout(): void {
        this._tokenService.deleteToken().subscribe(() => {
            this._localStorageService.clear();
            this._outlookService.removeState(LOCAL_STORAGE.USER_NAME);
            this._outlookService.removeState(LOCAL_STORAGE.TOKEN);
            this._outlookService.saveState();
            this._permissionService.deletePermissionList();

            this._router.navigate([ROUTES_DEFINITION.LOGIN]);
        });
    }
}
