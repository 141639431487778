import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EventService {

    constructor() {}

    /**
     * @description Control event and stop propagation.
     * @param  {any} event
     */
    eventControl(event: any): void {
        event.preventDefault();
        event.stopPropagation();
    }
    /**
     * @description Detect press any arrow key ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']
     * @param  {any} event
     */
    isKeyPressArrow(event: any): boolean {
        return (event.code === 'ArrowUp'
            || event.code === 'ArrowDown'
            || event.code === 'ArrowLeft'
            || event.code === 'ArrowRight');
    }

}
