import { AfterContentInit, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE } from 'src/app/constants/localStorage.constant';
import { AuthService } from 'src/app/core/auth/services/auth.service';
import { AutocompleteService } from 'src/app/core/services/autocomplete.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { OutlookService } from 'src/app/core/services/outlook.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
    selector: 'app-session-information',
    templateUrl: './session-information.component.html',
    styleUrls: ['./session-information.component.scss'],
})
export class SessionInformationComponent implements AfterContentInit {
    userName$: Observable<string>;

    constructor(
        private _outlookService: OutlookService,
        private _authService: AuthService,
        private _localStorageService: LocalStorageService,
        private _userService: UserService,
        private _autocompleteService: AutocompleteService
    ) {
        this.userName$ = this._userService.userNameObservable;
    }

    ngAfterContentInit(): void {
        this.setSessionInformation();
    }

    /**
     * @description Set necessary data for session information
     * @return void
     */
    setSessionInformation() {
        const OUTLOOK_STATE_USER_NAME: string = this._outlookService.getState(LOCAL_STORAGE.USER_NAME);
        this._userService.userNameObservableData(OUTLOOK_STATE_USER_NAME);
    }

    /**
     * @description Logout and navigate to login
     * @returns void
     */
    logout(): void {
        this._authService.logout();
        this._autocompleteService.controlSetTimeOutAutocomplete(() => {
            const TOKEN = this._localStorageService.getToken();
            if (!TOKEN?.length) {
                this._userService.userNameObservableData('');
            }
        }, 200);
    }
}
