import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class AutocompleteService {

    setTimeOutAutocomplete: any = null;
    defaulSetTimeOut: number = 700;
    
    constructor() {}

    /**
     * @description Execute function on timeOut with clear control
     * @param  {Function} executeFunction
     * @param  {number} timeOut
     */
    controlSetTimeOutAutocomplete(executeFunction: Function, timeOut?: number): void {
        if (this.setTimeOutAutocomplete) {
            clearTimeout(this.setTimeOutAutocomplete)
        }

        this.setTimeOutAutocomplete = setTimeout(() => executeFunction(), timeOut ?? this.defaulSetTimeOut);
    }

    /**
     * @description Format values for beauty displayed
     * @param  {any} value Any object form extract data
     * @param  {string[]} displayValues attribute list for display value
     */
    formatFormsValues(value: any, displayValues: string[]): string {
        let result = '';
        for (let index = 0; index < displayValues.length; index++) {
            if (value[displayValues[index]] !== undefined && value[displayValues[index]] !== null) {
                result += (index > 0) ? ' | ' + value[displayValues[index]] : value[displayValues[index]];
            }
        }
        return result.toString();
    }

}