<div class="container-wrapper">
    <div class="container-fluid" *ngIf="!isLogged">
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <img loading="lazy" class="romeu-logo" src="../assets/Romeu-logo.jpg" alt="Romeu logo" />
            </div>

            <div class="col-12 py-4 px-4 d-flex justify-content-center">
                <button type="button" label="Logged with microsoft" class="btn btn-sm btn-primary w-50" mat-raised-button (click)="login()">
                    Login
                </button>
            </div>
        </div>
    </div>

    <div class="container-fluid px-0">
        <div class="my-3 mx-3" *ngIf="!isIframe && isLogged">
            <app-session-information></app-session-information>
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row fw-light fs-6 text-secondary px-2 py-2">
            <div class="col-6 d-flex justify-content-start align-items-center">
                <span *ngIf="hostName?.length">{{ hostName }}</span>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <span class="bg-light rounded"> v2.2.0.0 </span>
            </div>
        </div>
    </div>
</div>
