export const OUTLOOK = {
    REQUEST_STATUS: {
        SUCCEEDED: 'succeeded',
    },

    HOSTNAME: {
        IOS: 'OutlookIOS',
        WEB_APP: 'OutlookWebApp',
        NEW_OUTLOOK_WINDOWS: 'newOutlookWindows',
    },

    EXTENSION: {
        EML: 'eml',
        MSG: 'msg',
    },

    DEFAULT_EMAIL_NAME: 'Email',
};
