import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './modules/login/login.module';

import { ToastrModule } from 'ngx-toastr';

import { InitializeService } from './core/services/initialize.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotifyService } from 'src/app/core/services/notify.service';
import { PatternService } from 'src/app/core/services/pattern.service';
import { EventService } from 'src/app/core/services/event.service';
import { AutocompleteService } from 'src/app/core/services/autocomplete.service';
import { OutlookService } from 'src/app/core/services/outlook.service';

import { AppComponent } from './app.component';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MicrosoftGraphService } from './core/microsoft-graph/microsoft-graph.service';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    if (logLevel === LogLevel.Error) {
        console.error(message);
    }

    if (logLevel === LogLevel.Warning) {
        console.warn(message);
    }
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.msalConfig.auth.clientId,
            authority: environment.msalConfig.auth.authority,
            postLogoutRedirectUri: environment.webUrl,
            redirectUri: environment.webUrl,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE,
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false,
            },
            allowRedirectInIframe: true,
            allowNativeBroker: true,
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.apiConfig.meGraphUrl, environment.apiConfig.scopes);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: environment.apiConfig.scopes,
        },
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        LoginModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-full-width',
            timeOut: 3000,
            preventDuplicates: true,
            includeTitleDuplicates: true,
        }),
        CoreModule.forRoot(),
        SharedModule.forRoot(),
        MsalModule,
    ],
    providers: [
        InitializeService,
        OutlookService,
        MicrosoftGraphService,
        NotifyService,
        LocalStorageService,
        PatternService,
        EventService,
        AutocompleteService,
        { provide: APP_INITIALIZER, useFactory: initApp, deps: [InitializeService], multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}

export function initApp(initializerService: InitializeService) {
    return (): Promise<any> => {
        return initializerService.initializeApp();
    };
}
