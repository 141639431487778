import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { MESSAGE } from 'src/app/constants/message.constant';
import { PERMISSION } from 'src/app/constants/permission.constant';
import { NotifyService } from '../notify.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private permissionList = new Array<string>();

    private permissionForVERMENUOPVFORWADINGObservable = new BehaviorSubject<boolean>(false);

    constructor() {}

    /**
     * @description Set permission list
     * @param  {string[]} newPermissionList
     * @returns void
     */
    public setPermissionList(newPermissionList: string[]): void {
        if (!newPermissionList || !newPermissionList.length) {
            return;
        }

        this.permissionList = newPermissionList;

        this.setPermissionForVERMENUOPVFORWADINGObservaleData(this.getPermissionForVERMENUOPVFORWADING());
    }

    /**
     * @desription Get permission list
     * @returns string[]
     */
    public getPermissionList(): string[] {
        return this.permissionList;
    }

    /**
     * @description Delete permission list
     * @returns void
     */
    public deletePermissionList(): void {
        this.permissionList = [];
    }

    /**
     * @description Get the permissions of the user by permission code
     * @param  {string} permissionCode Permission code from ROME
     * @returns boolean
     */
    public getPermissionByPermissionCode(permissionCode: string, showToast?: boolean, notifyService?: NotifyService): boolean {
        if (!this.permissionList) {
            return false;
        }

        if (!permissionCode || !permissionCode.length) {
            return false;
        }

        const havePermission: boolean = this.getPermissionList().some((somePermissionCode: string) => somePermissionCode === permissionCode);

        if (!havePermission && showToast && notifyService) {
            notifyService.warning(MESSAGE.YOU_DONT_HAVE_PERMISSION);
        }

        return havePermission;
    }

    /**
     * @description User have permission of VERINVOICINGBL
     * @returns boolean
     */
    public getPermissionForVERINVOICINGBL(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERINVOICINGBL, true);
    }

    /**
     * @description User have permission of VERINVOICINGSERVICE
     * @returns boolean
     */
    public getPermissionForVERINVOICINGSERVICE(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERINVOICINGSERVICE, true);
    }

    /**
     * @description User have permission of VERROMOCEAN
     * @returns boolean
     */
    public getPermissionForVERROMOCEAN(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERROMOCEAN, true);
    }

    /**
     * @description User have permission of VERPANELPRICE
     * @returns boolean
     */
    public getPermissionForVERPANELPRICE(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERPANELPRICE, true);
    }

    /**
     * @description User have permission of VERMENUADMIN
     * @returns boolean
     */
    public getPermissionForVERMENUADMIN(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERMENUADMIN, true);
    }

    /**
     * @description User have permission of VERPANELPROV
     * @returns boolean
     */
    public getPermissionForVERPANELPROV(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERPANELPROV, true);
    }

    /**
     * @description User have permission of VERMENUAUTO
     * @returns boolean
     */
    public getPermissionForVERMENUAUTO(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERMENUAUTO, true);
    }

    /**
     * @description User have permission of VERMENUAUTO
     * @returns boolean
     */
    public getPermissionForDOCINCFAC(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.DOCINCFAC, true);
    }

    /**
     * @description User have permission of VERMENUOPVFORWADING
     * @returns boolean
     */
    public getPermissionForVERMENUOPVFORWADING(): boolean {
        return this.getPermissionByPermissionCode(PERMISSION.VERMENUOPVFORWADING, true);
    }

    /**
     * @description Get user have permission of VERMENUOPVFORWADING Observable
     * @returns boolean
     */
    setPermissionForVERMENUOPVFORWADINGObservaleData(havePermission: boolean): void {
        this.permissionForVERMENUOPVFORWADINGObservable.next(havePermission);
    }

    /**
     * @description Get user have permission of VERMENUOPVFORWADING Observable
     * @returns boolean
     */
    get getPermissionForVERMENUOPVFORWADINGObservaleData(): Observable<boolean> {
        return this.permissionForVERMENUOPVFORWADINGObservable as Observable<boolean>;
    }

    /**
     * @description User have permission of VERINVOICINGBL
     * @returns boolean
     */
    public getPermissionForRouterByPermissionCode(allowedRoutes: string[], route: string, permissionCode: string): boolean {
        return allowedRoutes.includes(route) && this.getPermissionByPermissionCode(permissionCode, false);
    }
}
