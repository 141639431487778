export const MESSAGE = {
    // PERMISSIONS
    YOU_DONT_HAVE_PERMISSION: `You don't have permission, contact support`,
    YOU_DONT_HAVE_PERMISSION_FOR: (permissionFor: string) => {
        return `You don't have permission for ${permissionFor}, contact support`;
    },

    // ENTITY SELECT
    DETECTED_MULTIPLE_DOCUMENT_DIVISION: 'Detected multiple document divisions, please set vessel and voyage filter.',
    DOCUEMNT_NOT_FOUND_FOR_THIS_ENTITY: 'Documents not found for this entity.',
    SELECT_DOCUMENT_DIVISIONS: 'Select documents division',

    // ADD ATTACHMENT
    COULD_NOT_GET_ENTITY_DOCUMENTS: 'Could not get entity documents',
    NO_EXISTS_DOCUMENTS_ON_THIS_ENTITY: 'Not exist document on this entity',

    // EXPORT ATTACHMENT
    SUCCESFULLY_ATTACHMENT_FILENAME: (fileName: string): string => {
        return `Succesfully attachment file: ${fileName}`;
    },
    RETRIEVE_NUMBER_OF_DOCUMENTS: (numberOf: number): string => {
        return `${numberOf} Documents have been retrieved from email successfully`;
    },
    SUCCESFULLY_UPLOAD_DOCUMENTS: 'Successfully uploaded Documents',

    // ENTITY INFORMATION
    COULD_NOT_GET_ENTITY_INFORMATION: 'Could not get entity info',

    // DOCUMENTS
    SELECTED_ALL_REQUIRES_EVERY_ONE: 'Selecting all requires that everyone have the type of document informed',
    YOU_NEED_A_DOCUMENT_TYPE_SELECTED: 'You need a document type selected',
    YOU_NEED_A_ENVIOS_ID: 'Yo need a `Envios Id`',
    COULD_NOT_GET_TYPE_DOCUMENTS: 'Could not get Type Documents',
    NO_EXISTS_TYPE_DOCUMENTS: 'Not exist type documents',
    APPLY_ON_NUMBER_OF_DOCUMENTS: (numberOf: number): string => {
        return `Apply on ${numberOf} documents.`;
    },
    NOT_UPLOAD_MORE_THAN_ONE_DOCUMENT_WITH_A_SINGLE_DOCUMENT_TYPE:
        'It is not possible to upload more than one document with a single document type, check your selection.',

    // AUTODETECT
    AUTODETECT_ENTITY_CODE: (entityName: string, entityCode: string): string => {
        return `Auto-detect entity on subject. ${entityName}: ${entityCode}`;
    },
    AUTODETECT_VFORWARDING_CODE: (vforwardingCode: string): string => {
        return `Auto-detect code on subject. Code: ${vforwardingCode}`;
    },
};
