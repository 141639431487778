import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { DocDivision, DocDivisionFilter, DocDivisionHelper } from 'src/app/shared/models/docs/docDivions.model';
import { SearchTableIdFilter, SearchTableIdResult, SearchTableIdResultHelper } from 'src/app/shared/models/entity.model';
import { ServiceTypeDocument, ServiceTypeDocumentHelper } from 'src/app/shared/models/docs/document.model';
import { DocDownload, DocDownloadHelper } from 'src/app/shared/models/docs/docDownload.model';
import { UploadVForwardingDocument } from 'src/app/shared/models/docs//v-forwarding.model';
import { EntityType } from 'src/app/shared/enum/entity-type.enum';

@Injectable({
    providedIn: 'root',
})
export class DocumentationService {
    private endpoints = {
        getEntityIdByEntityCodeAndEntityType: environment.documentShippingApi + '/GetTableIdByEntityCodeAndType',
        getDocs: environment.documentShippingApi + '/GetDocs',
        getDocumentListByEntity: environment.documentShippingApi + '/GetDocsByEntity',
        getDocumentByEntity: environment.documentShippingApi + '/GetDocsByEntity?fileContent=true',
        getDocumentsByDivision: environment.shippingApi + '/api/TypeDocDivision/GetAllAsync',
        getTypeDocumentsByCompanyDivision: environment.documentShippingApi + '/GetTypeDocumentsByCompanyDivision',
        uploadDocument: environment.documentShippingApi + '/PostDoc',
        uploadVForwardingDocument: environment.documentShippingApi + '/PostVforwardingDocument',
    };

    constructor(private http: HttpClient) {}

    /**
     * @description Call API and get any document
     * @param  {SearchTableIdFilter} filter Any text
     * @returns Observable<SearchTableIdResult[]>
     */
    getEntityId(filter: SearchTableIdFilter): Observable<SearchTableIdResult[]> {
        return this.http
            .post(this.endpoints.getEntityIdByEntityCodeAndEntityType, filter)
            .pipe(map((result: { Data: any[] }) => SearchTableIdResultHelper.mapToObjectList(result.Data.at(0))));
    }

    /**
     * @description Call API and get any document
     * @param  {DocDivisionFilter} search Any text
     * @returns Observable<DocDivision[]>
     */
    getDocuments(tableName: string, tableId: number, fileId: number, divisionId: number): Observable<DocDivision> {
        const filter: string = `?tableName=${tableName}&tableId=${tableId}&fileContent=${true}&fileId=${fileId}&divisionId=${divisionId}`;
        return this.http.get(this.endpoints.getDocs + filter).pipe(map((result: any) => DocDownloadHelper.mapToObject(result.Data.at(0))));
    }

    /**
     * @description Call API and search document list by entity
     * @param  {DocDivisionFilter} search Any text
     * @returns Observable<DocDivision[]>
     */
    getDocumentListByEntity(filter: DocDivisionFilter): Observable<DocDownload[]> {
        return this.http
            .post(this.endpoints.getDocumentListByEntity, filter)
            .pipe(map((result: { Data: any[] }) => DocDownloadHelper.mapToObjectList(result.Data.at(0))));
    }

    /**
     * @description Call API and search document by entity
     * @param  {string} search Any text
     * @returns Observable<DocDivision[]>
     */
    getDocumentsGetDocsByEntity(filter: DocDivisionFilter, fileId: number): Observable<DocDivision> {
        return this.http
            .post(`${this.endpoints.getDocumentListByEntity}?fileContent=true&fileId=${fileId}`, filter)
            .pipe(map((result: { Data: any[] }) => DocDivisionHelper.mapToObject(result.Data.at(0))));
    }

    /**
     * @description Call API and search document by division
     * @param  {string} search Any text
     * @param  {number} divisionId
     * @param  {EntityType} entityType
     * @returns Observable<ServiceTypeDocument[]>
     */
    getDocumentsByCompanyDivision(search: string, divisionId: number, entityType: EntityType): Observable<ServiceTypeDocument[]> {
        const filter = '?search=' + search + '&companyDivisionId=' + divisionId + '&entityType=' + entityType;
        return this.http
            .post(this.endpoints.getTypeDocumentsByCompanyDivision + filter, null)
            .pipe(map((result: { Data: any[] }) => ServiceTypeDocumentHelper.mapToObjectList(result.Data.at(0))));
    }

    /**
     * @description Call API and search document by division
     * @param  {string} search Any text
     * @returns Observable<boolean>
     */
    uploadDocument(document: DocDownload): Observable<boolean> {
        return this.http.post(this.endpoints.uploadDocument, document).pipe(map((result: { Data: boolean[] }) => result.Data.at(0)));
    }

    /**
     * @description Call API and search document by division
     * @param  {string} document Any text
     * @returns UploadVForardingDocument<boolean[]>
     */
    uploadVForwardingDocument(document: UploadVForwardingDocument): Observable<boolean> {
        return this.http.post(this.endpoints.uploadVForwardingDocument, document).pipe(map((result: { Data: boolean[] }) => result.Data.at(0)));
    }
}
