export class DocDownload {
    constructor(
        public id?: number | string,
        public tableId?: number | null,
        public tableName?: string,
        public name?: string,
        public extension?: string,
        public reference?: string,
        public gdoFileId?: number,
        public typeId?: number,
        public type?: string,
        public typeDescription?: string,
        public isUnique?: boolean,
        public byteArray?: any,
        public division?: number | null,
        public dateTCreationDate?: Date,
        public creationUser?: string,
        public tModificationDate?: Date,
        public modificationTime?: string,
        public modificationUser?: string,
        public includeInInvoice?: boolean,
        public outlookId?: string,
        public selected?: boolean,
        public companyDivisionId?: number | null
    ) {}
}

export class DocDownloadHelper {
    static DefaultObject(): DocDownload {
        return new DocDownload(
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            false,
            null
        );
    }

    static mapToObject(result): DocDownload {
        return new DocDownload(
            result.Id,
            result.TableId,
            result.TableName,
            result.Name,
            result.Extension,
            result.Reference,
            result.GdoFileId,
            result.TypeId,
            result.Type,
            result.TypeDescription,
            result.IsUnique ?? false,
            result.ByteArray,
            result.Division,
            result.DateTCreationDate,
            result.CreationUser,
            result.TModificationDate,
            result.ModificationTime,
            result.ModificationUser,
            result.IncludeInInvoice,
            null,
            false,
            null
        );
    }

    static mapToObjectList(result): any[] {
        const items = [];
        const itemsToTransform = result.Data || result;
        itemsToTransform.forEach((item) => {
            items.push(this.mapToObject(item));
        });

        return items;
    }
}
