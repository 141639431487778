import { EntityType } from '../../enum/entity-type.enum';
import { DivisionTable, DivisionTableHelper } from '../division.model';
import { DocDownload, DocDownloadHelper } from '../docs/docDownload.model';

export class DocDivisionFilter {
    constructor(
        public entityType: EntityType,
        public code: string,
        public userName: string,
        public tableId?: number,
        public divisions?: DocDivision[]
    ) {}
}
export class DocDivision {
    constructor(
        public divisionList?: DivisionTable[],
        public documentList?: DocDownload[],
        public tableId?: number,
    ) { }
}

export class DocDivisionHelper {
    static mapToObject(result): DocDivision {
        return new DocDivision(
            result.Divisions !== null ? DivisionTableHelper.mapToObjectList(result.Divisions) : [],
            result.Documents !== null ? DocDownloadHelper.mapToObjectList(result.Documents) : [],
            result.TableId,

        );
    }
}