import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
    production: true,
    web: false,
    appId: 3,
    validationApi: 'https://fde-shippy-authapi-pro-ddemajg0bvfch8g8.z01.azurefd.net',
    shippingApi: 'https://pro-shippy-api.romeushipping.com',
    documentShippingApi: 'https://pro-docs-api.romeushipping.com/api/v1',
    webUrl: 'https://m365-app.romeushipping.com/',
    msalConfig: {
        auth: {
            clientId: 'f4668636-8984-45a5-ac02-8894dbd9ac92',
            authority: 'https://login.microsoftonline.com/common',
        },
    },
    apiConfig: {
        scopes: [
            'user.read',
            'mail.read',
            'mail.read.shared',
            'mail.readbasic',
            'mail.readbasic.shared',
            'mail.readwrite',
            'mail.readwrite.shared',
            'mailboxsettings.read',
            'user.readBasic.all',
            'user.read',
        ],
        meGraphUrl: 'https://graph.microsoft.com/v1.0/me',
        usersGraphUrl: 'https://graph.microsoft.com/v1.0/users',
    },
};
