import { Injectable } from '@angular/core';
import { LOCAL_STORAGE } from 'src/app/constants/localStorage.constant';
import {
    IMicrosoftGraphAccessToken,
    IMicrosoftGraphRefreshToken,
    IMicrosoftGraphSystemInformation,
} from 'src/app/shared/models/microsoft-graph/microsoft-graph-token.model';
import { IMicrosoftGraphUserSimple } from 'src/app/shared/models/microsoft-graph/microsoft-graph-user.model';

@Injectable()
export class LocalStorageService {
    constructor() {}

    /**
     *
     * @description Get Token from LocalStorage
     * @returns string
     */
    public getToken(): string {
        return this.getItem<string>(LOCAL_STORAGE.TOKEN) ?? '';
    }

    /**
     *
     * @description Set Token on LocalStorage
     * @returns string
     */
    public setToken(token: string = ''): void {
        this.setItem(LOCAL_STORAGE.TOKEN, token);
    }

    /**
     *
     * @description Get User Name from LocalStorage
     * @returns string
     */
    public getUserName(): string {
        return this.getItem<string>(LOCAL_STORAGE.USER_NAME) ?? '';
    }

    /**
     *
     * @description Set User Name from LocalStorage
     * @returns string
     */
    public setUserName(userName: string = ''): void {
        return this.setItem(LOCAL_STORAGE.USER_NAME, userName);
    }

    /**
     *
     * @description Get delegated email user id from LocalStorage
     * @returns string
     */
    public getDelegatedEmail(): string | null {
        return this.getJsonItem<string>(LOCAL_STORAGE.DELEGATED_EMAIL) ?? '';
    }

    /**
     *
     * @description Set delegated email on LocalStorage
     * @returns string
     */
    public setDelegatedEmail(delegatedMail: string): void {
        if (!delegatedMail) {
            return;
        }

        this.setJsonItem(LOCAL_STORAGE.DELEGATED_EMAIL, delegatedMail);
    }

    /**
     * @description Clear delegated email from LocalStorage
     * @returns void
     */
    public clearDelegatedEmail(): void {
        this.clearLocalStorageKeys([this.getKeyFromLocalStorageByPartialKey(LOCAL_STORAGE.DELEGATED_EMAIL)]);
    }

    /**
     *
     * @description Get Microsoft Graph access token object from LocalStorage
     * @returns IMicrosoftGraphAccessToken | null
     */
    public getMicrosoftGraphAccessToken(): IMicrosoftGraphAccessToken | null {
        const MICROSOFT_GRAPH_ACCESS_TOKEN_KEY: string = this.getKeyFromLocalStorageByPartialKey(LOCAL_STORAGE.MICROSOFT.PARTIAL_ACCESS_TOKEN);
        const MICROSOFT_GRAPH_ACCESS_TOKEN = this.getJsonItem<IMicrosoftGraphAccessToken | null>(MICROSOFT_GRAPH_ACCESS_TOKEN_KEY) ?? null;
        return MICROSOFT_GRAPH_ACCESS_TOKEN;
    }

    /**
     *
     * @description Get Microsoft Graph refresh token object from LocalStorage
     * @returns IMicrosoftGraphRefreshToken | null
     */
    public getMicrosoftGraphRefreshToken(): IMicrosoftGraphRefreshToken | null {
        const MICROSOFT_GRAPH_REFRESH_TOKEN_KEY: string = this.getKeyFromLocalStorageByPartialKey(LOCAL_STORAGE.MICROSOFT.PARTIAL_REFRESH_TOKEN);
        const MICROSOFT_GRAPH_REFRESH_TOKEN = this.getItem<IMicrosoftGraphRefreshToken | null>(MICROSOFT_GRAPH_REFRESH_TOKEN_KEY) ?? null;
        return MICROSOFT_GRAPH_REFRESH_TOKEN;
    }

    /**
     * @description Clear Microsoft Graph items from LocalStorage
     * @returns void
     */
    public clearMicrosoftGraphItems(): void {
        this.clearLocalStorageKeys([
            this.getKeyFromLocalStorageByPartialKey(LOCAL_STORAGE.MICROSOFT.PARTIAL_ACCESS_TOKEN),
            this.getKeyFromLocalStorageByPartialKey(LOCAL_STORAGE.MICROSOFT.PARTIAL_REFRESH_TOKEN),
            this.getKeyFromLocalStorageByPartialKey(LOCAL_STORAGE.MICROSOFT.PARTIAL_SYSTEM_INFORMATION),
        ]);
    }

    /**
     * @private
     * @description Removed All LocalStorage items
     * @returns void
     */
    public clear(): void {
        localStorage.clear();
    }

    /**
     * @private
     * @description Get LocalStorage item with specified Key
     * @param  {string} key
     * @returns T
     */
    private getItem<T>(key: string): T {
        return localStorage.getItem(key) as T;
    }

    /**
     * @private
     * @description Get JSON parsed LocalStorage item with specified Key
     * @param  {string} key
     * @returns T
     */
    private getJsonItem<T>(key: string): T {
        return JSON.parse(localStorage.getItem(key)) as T;
    }

    /**
     * @private
     * @description Set LocalStorage item with specified Key
     * @param  {string} key
     * @param  {any} value
     * @returns void
     */
    private setItem(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    /**
     * @private
     * @description SetJSON parsed LocalStorage item with specified Key
     * @param  {string} key
     * @param  {any} value
     * @returns void
     */
    private setJsonItem(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     * @private
     * @description Remove specific items from LocalStorage
     * @param  {string[]} keys - Array of keys to remove
     * @returns void
     */
    private clearLocalStorageKeys(keyList: string[]): void {
        if (!keyList?.length) {
            return;
        }

        keyList.forEach((key) => {
            localStorage.removeItem(key);
        });
    }

    /**
     * @private
     * @description Retrieve the complete key from localStorage using a partial key
     * @param partialKey Partial key to search for
     * @return The complete key if found, or empty string if not found
     */
    private getKeyFromLocalStorageByPartialKey(partialKey: string): string {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key.includes(partialKey)) {
                return key;
            }
        }
        return '';
    }
}
